import _ from "underscore";
import {
  removeCommaFromString,
  getRRuleStringFromRecurrence,
  handleError,
  convertToTimeZone,
} from "../services/commonUsefulFunctions";
import { getISODay, parseISO, startOfDay, subDays } from "date-fns";
import { type Options as RRuleOptions, RRule, Weekday } from "rrule";
import { getEventEtag, getEventMasterEventID, getEventRecurrence, getEventUserCalendarID } from "../services/eventResourceAccessors";
import { isEmptyObjectOrFalsey, isTypeNumber, isTypeString } from "../services/typeGuards";
import { addDefaultToObject } from "./objectFunctions";
import { isOutlookEvent } from "./eventFunctions";

export function updateOriginalRecurringEventIndex(param: {
  currentEvent: VimcalEvent
  originalEvent: VimcalEvent
  originalRecurrenceEventIndex: OriginalRecurrenceEventIndex
}) {
  const { currentEvent, originalEvent, originalRecurrenceEventIndex } = param;

  if (isEmptyObjectOrFalsey(currentEvent) || isEmptyObjectOrFalsey(originalEvent)) {
    return originalRecurrenceEventIndex;
  }

  // original recurrence index is an index of an index
  // need to separate out recurrence id per calendar because different calenders could pull different recurrence events
  let updatedIndex = {...originalRecurrenceEventIndex};
  if (!updatedIndex[getEventUserCalendarID(currentEvent)]) {
    updatedIndex = {
      ...updatedIndex,
      [getEventUserCalendarID(currentEvent)]: {},
    };
  }

  const uniqueRecurringKey = determineOriginalEventIndexKey(currentEvent);

  if (!uniqueRecurringKey) {
    return updatedIndex;
  }

  updatedIndex = {
    ...updatedIndex,
    [getEventUserCalendarID(currentEvent)]: {
      ...updatedIndex[getEventUserCalendarID(currentEvent)],
      [uniqueRecurringKey]: originalEvent,
    },
  };

  return updatedIndex;
}

export function resetOriginalRecurringEventIndex(param: {
  currentEvent: VimcalEvent
  originalRecurrenceEventIndex: OriginalRecurrenceEventIndex
}) {
  const { currentEvent, originalRecurrenceEventIndex } = param;

  const updatedIndex = _.clone(originalRecurrenceEventIndex);
  updatedIndex[getEventUserCalendarID(currentEvent)] = {};

  return updatedIndex;
}

function determineOriginalEventIndexKey(event: VimcalEvent) {
  if (!getEventMasterEventID(event)) {
    return null;
  }

  // need etag because if event changes, we don't want to grab an old cache
  // caveat: for recurring events that are one off (part of series but changed this event), we won't be able to cache it at the beginning
  return `${getEventMasterEventID(event)}_${removeCommaFromString(getEventEtag(event))}`;
}

export function getOriginalRecurringEventFromIndex(
  event: VimcalEvent,
  originalRecurrenceEventIndex: OriginalRecurrenceEventIndex,
  originalEventUserCalendarID: string,
): VimcalEvent | VimcalPrivateEvent | null {
  if (isEmptyObjectOrFalsey(event) || isEmptyObjectOrFalsey(originalRecurrenceEventIndex)) {
    return null;
  }

  // since we don't strictly remove stale cache, this could cause an error somewhere down the road
  const key = determineOriginalEventIndexKey(event);

  if (!key) {
    return null;
  }

  const originalRecurrence = originalRecurrenceEventIndex?.[originalEventUserCalendarID]?.[key];
  if (originalRecurrence) {
    // get master event recurring info from the original calendar that you copied from
    return originalRecurrence;
  }

  return (
    originalRecurrenceEventIndex?.[getEventUserCalendarID(event)]?.[key] ?? null
  );
}

export function removeOriginalRecurringEventFromIndex({ event, originalRecurrenceEventIndex }: {
  event: VimcalEvent
  originalRecurrenceEventIndex: OriginalRecurrenceEventIndex
}) {
  try {
    const masterEventID = determineOriginalEventIndexKey(event);
    const eventUserCalendarID = getEventUserCalendarID(event);
    if (!masterEventID) {
      return addDefaultToObject(originalRecurrenceEventIndex);
    }
    if (!originalRecurrenceEventIndex?.[eventUserCalendarID]?.[masterEventID]) {
      return addDefaultToObject(originalRecurrenceEventIndex);
    }
    const { [masterEventID]: oldValue, ...updatedObject } = originalRecurrenceEventIndex[eventUserCalendarID];
    return {
      ...originalRecurrenceEventIndex,
      [eventUserCalendarID]: {
        ...updatedObject,
      },
    };
  } catch (error) {
    handleError(error);
    return addDefaultToObject(originalRecurrenceEventIndex);
  }
}

export function originalEventToRecurringEventIndexKey(recurringEventId: string, email: string) {
  return `${recurringEventId}_${email}`;
}

// For Google's API, a given instance is the first
// instance of a recurring event if its
// 'originalStartTime' key is equal to the 'start'
// key of the master copy of the recurring event
export function isEventFirstRecurringInstance(
  instanceOriginalStartTime: VimcalEventTime,
  recurringEventStartTime: VimcalEventTime,
) {
  const isSameDateTime =
    !!instanceOriginalStartTime.dateTime &&
    !!recurringEventStartTime.dateTime &&
    instanceOriginalStartTime.dateTime === recurringEventStartTime.dateTime;
  const isSameDate =
    !!instanceOriginalStartTime.date &&
    !!recurringEventStartTime.date &&
    instanceOriginalStartTime.date === recurringEventStartTime.date;
  const isSameTimeZone =
    instanceOriginalStartTime.timeZone === recurringEventStartTime.timeZone;

  return (isSameDateTime || isSameDate) && isSameTimeZone;
}

export function trimOriginalRecurrenceRule(ruleString: string, cutoffDate: Date) {
  // Combining UNTIL and COUNT in the same RRULE results in the rule being
  // rejected by Google with: Google::Apis::ClientError invalid: Invalid recurrence rule.
  // https://tools.ietf.org/html/rfc5545#section-3.3.10
  const rule = RRule.fromString(cleanRRuleString(ruleString)).origOptions;
  rule.until = startOfDay(cutoffDate);
  rule.count = null; // having count here breaks deletion. This is some what of a hack since it breaks because the count is incorrect but still works because we have until

  return new RRule(rule).toString();
}

// keep same rule except update the day of week
export function updateRecurrenceWithNewDayOfWeek(rruleString: string, newDate: Date) {
  // Combining UNTIL and COUNT in the same RRULE results in the rule being
  // rejected by Google with: Google::Apis::ClientError invalid: Invalid recurrence rule.
  // https://tools.ietf.org/html/rfc5545#section-3.3.10

  // if multiple days -> keep by weekday
  // if only one day -> move day
  // origOptions:
  //  [Weekday]
  //  2
  const rule = RRule.fromString(cleanRRuleString(rruleString)).origOptions;
  const repeatOnWeekDays = getRepeatOnWeekDay(rule);

  if (!rule.byweekday
    || repeatOnWeekDays.length === 0
    || repeatOnWeekDays.length > 1
  ) {
    return rruleString;
  }

  //! do not go into the object to set it
  // rule.byweekday = [{
  //   weekday: getISODay(newDate) - 1,
  //   n: rule.byweekday[0].n
  // }];
  rule.byweekday = getISODay(newDate) - 1;
  return new RRule(rule).toString();
}

export function getParsedRRUle(rruleString: string) {
  return RRule.fromString(cleanRRuleString(rruleString));
}

export function getRepeatOnWeekDay(rrule: Partial<RRuleOptions>) {
  if (!rrule?.byweekday) {
    return [];
  }

  if (!Array.isArray(rrule.byweekday)) {
    return [rrule.byweekday];
  }

  return rrule.byweekday;
}

export function isDayOfWeekInByWeekDayRecurrence(rrule: Partial<RRuleOptions>, date: Date) {
  const repeatOnWeekDays = getRepeatOnWeekDay(rrule);

  if (repeatOnWeekDays.length === 0) {
    return false;
  }

  const includedWeekDays = repeatOnWeekDays.map(day => {
    if (isTypeNumber(day)) {
      return day;
    }

    if (isTypeString(day)) {
      return Weekday.fromStr(day).weekday;
    }

    return day.weekday;
  });
  const dateDayOfWeek = getISODay(date) - 1;
  return includedWeekDays.includes(dateDayOfWeek);
}

export function updatedRecurrenceAfterEventStartChange(originalRecurringEventInstance: VimcalEvent, startDate: Date) {
  const originalRecurrenceString = getRRuleStringFromRecurrence(originalRecurringEventInstance);
  if (!originalRecurrenceString) {
    return [""];
  }

  const originalRecurrence = getEventRecurrence(originalRecurringEventInstance);
  // need to update recurrence if start date changed
  const originalRRule = RRule.fromString(cleanRRuleString(originalRecurrenceString)).origOptions;

  // you could have multiple days in originalRRule.byweekday
  if (getRepeatOnWeekDay(originalRRule).length === 1
    && !isDayOfWeekInByWeekDayRecurrence(originalRRule, startDate)
  ) {
    return [updateRecurrenceWithNewDayOfWeek(originalRecurrenceString, startDate)];
  }

  return originalRecurrence;
}

export function cleanRRuleString(rruleString: string) {
  if (!rruleString) {
    return "";
  }
  // need to filer out removeBusyMacParam
  // remove exclude for fullCalendar: https://stackoverflow.com/questions/56580678/is-exdate-not-included-in-rrule-for-full-calendar
  // const testString = "FREQ=WEEKLY;DTSTART=20190607T090000;EXDATE=20190705T090000;INTERVAL=4;BYDAY=FR"
  const removedBusyMac = removeBusyMacParam(rruleString);
  // const removeExcludeDate = removedBusyMac
  //   // .replaceAll(/EXDATE.*;/g, '') // delete EXDATE to ;
  //   .replaceAll("EXDATE", ""); // sanity check to delete rest of exdate
  //   console.log("removeExcludeDate_", removeExcludeDate);
  return removedBusyMac;
}

function removeBusyMacParam(rRuleString: string) {
  // busy cal adds this param which breaks rrule
  if (!rRuleString) {
    return "";
  }

  if (rRuleString.includes("X-BUSYMAC-REGENERATE=TRASH")) {
    return rRuleString
      .replace(/X-BUSYMAC-REGENERATE=TRASH;/g, "")
      .replace(/;X-BUSYMAC-REGENERATE=TRASH/g, "")
      .replace(/X-BUSYMAC-REGENERATE=TRASH/g, "")
      .trim();
  }

  return rRuleString;
}

// mostly for this and following events
export function createRecurrenceCutOffDate(event: BigCalendarEvent) {
  const {
    allDay,
    eventStart,
    start_time_utc,
  } = event;
  // If we don't convert to UTC for Outlook, there can be a bug when the event has a
  // different date in the current time zone vs UTC.
  const convertedEventStart = isOutlookEvent(event) && start_time_utc
    ? convertToTimeZone(new Date(parseISO(start_time_utc)), { timeZone: "UTC" })
    : eventStart;

  if (allDay) {
    return startOfDay(subDays(convertedEventStart, 1));
  }

  return startOfDay(convertedEventStart);
}
