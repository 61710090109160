import {
  getEmailBasedOnCalendarId,
  getTimeInAnchorTimeZone,
  isAfterMinute,
  isBeforeMinute,
  guessTimeZone,
  RoundToClosestMinuteJSDate,
  isSameOrBeforeMinute,
  isSameOrAfterMinute,
  getBrightness,
  isEditable,
  dimDarkModeColor,
  shouldReduceTextSize,
  isUserOnlyPersonWhoAcceptedAndRestDeclined,
  isOrganizer,
  createFadedColorIfIndexDoesNotExist,
  getFadedColor,
  GetLetterAndNumberHotKeyCombinations,
  isSameOrAfterDay,
  removeDuplicatesFromArray,
  filterOutResourceAttendees,
  NO_RESPONSE_NEEDED,
  formatEventForReactBigCalendar,
  generateConferenceRooms,
  hasPermissionToModify,
  sendMessageToSentry,
  replaceStringInLinkTag,
} from "../services/commonUsefulFunctions";
import GoogleCalendarService, {
  EDITABLE_ROLES,
  ATTENDEE_EVENT_NEEDS_ACTION,
  TEMPORARY,
  AVAILABILITY,
  OUT_OF_OFFICE_EVENT_TYPE,
  PRIVATE,
  SELF_RESPONSE_CANCELLED,
  FREE_DURING_EVENT,
  ATTENDEE_EVENT_ATTENDING,
  ATTENDEE_EVENT_TENTATIVE,
  ATTENDEE_EVENT_DECLINED,
  GOOGLE_EVENT_VISIBILILITY_OPTIONS,
  OUTLOOK_EVENT_VISIBILILITY_OPTIONS,
} from "../services/googleCalendarService";
import {
  parseISO,
  startOfMinute,
  subMinutes,
  addMinutes,
  differenceInMinutes,
  parseJSON,
  differenceInMonths,
  startOfDay,
  endOfDay,
  isSameDay,
} from "date-fns";
import StyleConstants, {
  DARK_MODE_BACKGROUND_COLOR,
  MERGED_EVENTS,
  SELECT_AVAILABILITY_COLOR,
  FADED_GREY_TEXT_COLOR_DARK_MODE,
  DEFAULT_PRIMARY_CALENDAR_COLOR,
  EXCLUDED_DOMAINS,
  RSVP_WITHOUT_SHOW_AS,
} from "../services/globalVariables";
import GoogleColors from "../services/googleColors";
import { TinyColor } from "@ctrl/tinycolor";
import { isEventSlotAllDayEvent } from "./rbcFunctions";
import {
  determineCalendarColor,
  getAllEditableCalendars,
  getCalendarFromEmail,
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
  isCalendarOutlookCalendar,
  isValidCalendar,
} from "./calendarFunctions";
import {
  getEventAttendeeComment,
  getEventAttendees,
  getEventCategories,
  getEventColorHex,
  getEventColorID,
  getEventDescription,
  getEventEnd,
  getEventExtendedProperties,
  getEventGuestPermissions,
  getEventID,
  getEventLocation,
  getEventMasterEventID,
  getEventOrganizer,
  getEventProvider,
  getEventReminders,
  getEventStart,
  getEventStatus,
  getEventTitle,
  getEventTransparency,
  getEventType,
  getEventUserCalendarID,
  getEventUserEmail,
  getEventUserEventID,
  getEventVisibility,
  getOutlookEventShowAs,
  GUESTS_CAN_MODIFY,
  GUESTS_CAN_SEE_OTHER_GUESTS,
  isAllDayOutlookEvent,
} from "../services/eventResourceAccessors";
import {
  getCalendarEditRole,
  getCalendarOwnerEmail,
} from "../services/calendarAccessors";
import { CALENDAR_PROVIDERS } from "./vimcalVariables";
import { isVersionV2 } from "../services/versionFunctions";
import { useAvailabilityStore } from "../services/stores/availabilityStores";
import {
  getEventTagColor,
  shouldUseTagColorForGoogleEvent,
} from "./tagsFunctions";
import { parseOutlookAllDayEvent } from "./timeFunctions";
import { getSelectedUserName, getUserEmail } from "./userFunctions";
import { shouldUseTagColorAsEventColorForOutlookEvent } from "./outlookFunctions";
import { isMeetWithEvent } from "./meetWithFunctions";
import { isEmptyArray } from "./arrayFunctions";
import { isOutlookShowAsTentativeEvent } from "../resources/outlookVariables";
import { getObjectEmail } from "./objectFunctions";
import { getAllCalendarsFromStore } from "./zustandFunctions";
import { OUTLOOK_COLORS } from "../services/outlookColors";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "../services/typeGuards";
import {
  getRecentContacts,
  getRecentlySearchedContacts,
} from "./stateManagementFunctions";
import { getEmailDomain, isSameEmail, isValidEmail, lowerCaseAndTrimString, lowerCaseAndTrimStringWithGuard } from "./stringFunctions";
import { createUUID } from "../services/randomFunctions";
import classNames from "classnames";
import { sanitizeStringAndLinkify } from "./jsVariables";
import { isWBEmail } from "./featureFlagFunctions";

let _staticEventIndex = {};

export const TYPE_FOCUS_BLOCK = "focus-block";
const SPECIFIED_TIME_ZONE = "specifiedTimeZone"; // if an event's time zone has been explicitly specified
/**
 * Take a grouped attendee from the select attendee auto-complete dropdown and
 * split into individual attendees, matching names and emails.
 *
 * @param {{emailArray?: string[], label: string, name: string, value: string}} groupedAttendees
 * @param {User} currentUser
 * @param {Record<string, string>} emailToNameIndex
 */
export function splitGroupedAttendees(
  groupedAttendees,
  currentUser,
  emailToNameIndex = {}
) {
  if (
    isEmptyArrayOrFalsey(groupedAttendees.emailArray) ||
    groupedAttendees.emailArray.length === 1
  ) {
    return [groupedAttendees];
  }

  const splitPattern = /(?:,\s+)|(?:,?\s+&\s+)/;

  const attendeeCount = groupedAttendees.emailArray.length;
  let splitNames =
    attendeeCount === 2
      ? groupedAttendees.name.trim().split(splitPattern)
      : groupedAttendees.name
        .trim()
        .split(splitPattern)
        .map((n) => ((n ?? "").startsWith("& ") ? n.substring(2) : n));

  if (splitNames.length !== attendeeCount) {
    sendMessageToSentry(
      "Error splitting attendees",
      groupedAttendees.emailArray.join(", ")
    );
    splitNames = [];
  }

  const recentContacts = [
    ...getRecentContacts(currentUser),
    ...getRecentlySearchedContacts(currentUser),
  ];

  return groupedAttendees.emailArray.map((email, index) => {
    const name =
      splitNames[index] ??
      emailToNameIndex[email] ??
      recentContacts.find((c) => c.email === email)?.name ??
      "";
    return {
      label: name || email,
      name,
      value: email,
    };
  });
}

export function getListOfEmailsFromString(string, filterEmail = []) {
  if (!string || string.length === 0) {
    return;
  }

  let attendeesStringFiltered = string
    .trim()
    .replace(/,/g, " ")
    .replace(/\t/g, " ")
    .replace(/</g, " ")
    .replace(/>/g, " ")
    .toLowerCase();

  let attendeesArray = attendeesStringFiltered.split(" ");

  if (attendeesArray.length > 1) {
    return attendeesArray.filter(
      (e) => isValidEmail(e) && !filterEmail.includes(e)
    );
  }

  return [attendeesStringFiltered];
}

export function doesEventAskForResponse(param) {
  if (isEmptyObjectOrFalsey(param)) {
    // safety check
    return false;
  }

  const { event, allCalendars } = param;

  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  const email = getEmailBasedOnCalendarId(event, allCalendars);
  const attendees = getEventAttendees(event);
  if (attendees && email) {
    const attendeeEmails = attendees
      .filter((a) => a?.email)
      .map((a) => a.email.toLowerCase().trim());
    const formattedEmail = email.toLowerCase().trim();

    return attendeeEmails.includes(formattedEmail);
  }

  return false;
}

export function determineAdditionalBroadcastMessaging(hideRender) {
  return hideRender ? "_popup" : "";
}

function createAvailabilitySlot(params) {
  const {
    slot,
    timeZone,
    currentTimeZone,
    explicitlySpecifyTimeZone = false // so on time travel, we don't reset the event again
  } = params;

  const result = {
    isTemporary: true,
    isAvailability: true,
    eventStart:
      timeZone !== currentTimeZone
        ? getTimeInAnchorTimeZone(
            parseISO(slot.eventStart),
            timeZone,
            currentTimeZone
          )
        : parseISO(slot.eventStart),
    index: slot.index,
    eventEnd:
      timeZone !== currentTimeZone
        ? getTimeInAnchorTimeZone(
            parseISO(slot.eventEnd),
            timeZone,
            currentTimeZone
          )
        : parseISO(slot.eventEnd),
    rbcEventEnd:
      timeZone !== currentTimeZone
        ? getTimeInAnchorTimeZone(
            parseISO(slot.rbcEventEnd),
            timeZone,
            currentTimeZone
          )
        : parseISO(slot.rbcEventEnd),
    status: GoogleCalendarService.availability,
    raw_json: {
      ...slot.raw_json,
      ...{ status: GoogleCalendarService.availability },
    },
    id: `last_slot_${createUUID()}`,
  };

  if (explicitlySpecifyTimeZone) {
    result[SPECIFIED_TIME_ZONE] = currentTimeZone;
  }

  return result;
}

export function hasEventTimeZoneBeenExpliciltySpecified(event) {
  return !!getExplicitlySpecifiedTimeZone(event);
}

export function getExplicitlySpecifiedTimeZone(event) {
  return event?.[SPECIFIED_TIME_ZONE];
}

export function getPreviouslySelectedSlots(currentTimeZone, currentUser) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return;
  }

  const state = useAvailabilityStore.getState();
  const { lastSelectedTimeZone, lastSelectedSlots } = state;

  if (
    isEmptyArrayOrFalsey(lastSelectedSlots) ||
    !lastSelectedTimeZone
  ) {
    return null;
  }

  let slots = [];

  lastSelectedSlots.forEach((s, index) => {
    const slot = createAvailabilitySlot({
      slot: s,
      timeZone: lastSelectedTimeZone,
      currentTimeZone,
      index,
      explicitlySpecifyTimeZone: true,
    });

    if (
      isAfterMinute(
        getTimeInAnchorTimeZone(
          slot.eventEnd,
          currentTimeZone,
          guessTimeZone()
        ),
        new Date()
      )
    ) {
      if (
        isBeforeMinute(
          getTimeInAnchorTimeZone(
            slot.eventStart,
            currentTimeZone,
            guessTimeZone()
          ),
          new Date()
        )
      ) {
        slot.eventStart = startOfMinute(
          RoundToClosestMinuteJSDate(
            getTimeInAnchorTimeZone(
              new Date(),
              guessTimeZone(),
              currentTimeZone
            ),
            5
          )
        );
      }
      slots = slots.concat(slot);
    }
  });

  if (slots.length === 0) {
    return null;
  }

  return slots;
}

export function getNowOrUpcomingEvent(listOfEvents, mainCalendars = []) {
  if (isEmptyArrayOrFalsey(listOfEvents)) {
    return null;
  }

  let upcomingEvent;
  let nowPreviousBar = subMinutes(new Date(), 10);
  let nowLaterBar = addMinutes(new Date(), 10);
  listOfEvents.forEach((e) => {
    if (e.displayAsAllDay) {
      return;
    }

    if (!mainCalendars.includes(getEventUserCalendarID(e))) {
      return;
    }

    if (
      isSameOrBeforeMinute(parseISO(e.defaultStartTime), nowLaterBar) &&
      isSameOrAfterMinute(parseISO(e.defaultEndTime), nowPreviousBar)
    ) {
      upcomingEvent = e;
    } else if (isSameOrAfterMinute(e.eventStart, new Date())) {
      if (!upcomingEvent) {
        upcomingEvent = e;
      } else if (isBeforeMinute(e.eventStart, upcomingEvent.eventStart)) {
        upcomingEvent = e;
      }
    }
  });

  return upcomingEvent;
}

export function determinedMergedEventBackgroundColor(
  event,
  addPadding = false
) {
  return shouldShowTransparentMergedBackground(event)
    ? `border-radius-2px default-background-color ${
        addPadding ? "margin-left-negative-4 pl-1 margin-right-negative-4" : ""
      }`
    : "";
}

export function shouldShowTransparentMergedBackground(event) {
  return (
    isMergedEvent(event) &&
    event?.selfAttendingStatus === ATTENDEE_EVENT_NEEDS_ACTION
  );
}

export function isMergedEvent(event) {
  return event && event[MERGED_EVENTS] && event[MERGED_EVENTS]?.length > 0;
}

export function isColorDim(color) {
  return getBrightness(color) <= 105;
}

export function shouldShowReducedHeightTransparentMergedEvent(event) {
  return (
    shouldShowTransparentMergedBackground(event) &&
    differenceInMinutes(event.eventEnd, event.eventStart) <= 30
  );
}

export function isEventBetween15And30Minutes(event) {
  let minDiff = differenceInMinutes(event.eventEnd, event.eventStart);
  return minDiff <= 30 && minDiff > 15;
}

export function createStaticInformation({
  e,
  currentUser,
  allCalendars,
  where = "",
  outlookCategories,
}) {
  const protectedAllCalendars = allCalendars ?? getAllCalendarsFromStore();
  const isDisplayEventEditable = (eventCalendarEmail) => {
    const hasPermissionToEditEventOwner = () => {
      return (
        isValidCalendar(protectedAllCalendars[getEventUserCalendarID(e)]) &&
        EDITABLE_ROLES.includes(
          getCalendarEditRole(protectedAllCalendars[getEventUserCalendarID(e)])
        )
      );
    };

    if (isPreviewOutlookEvent(e)) {
      // no reason to return true or false since in reschedule, we explicitly check if it's equal to false
      return;
    }

    // let canPrint = event && event.summaryUpdatedWithVisibility === 'from personal -> invite others and see guest list';
    if (isTemporaryEvent(e)) {
      return true;
    } else if (isOrganizerSelf(e) && hasPermissionToEditEventOwner(e)) {
      return true;
    } else if (
      getEventMasterEventID(e) &&
      getEventAttendees(e)?.length > 0 &&
      !isSameEmail(getObjectEmail(getEventOrganizer(e)), getUserEmail(currentUser))
    ) {
      // if recurring event and you're not organizer -> can not edit (Google bug)
      // https://stackoverflow.com/questions/63259190/an-event-that-is-guests-can-modify-is-not-update-via-google-calendar-api
      // https://issuetracker.google.com/issues/36757203
      return false;
    } else if (
      isEditable({
        event: e,
        allCalendars: protectedAllCalendars,
      })
    ) {
      const eventAttendees = getEventAttendees(e);
      if (!eventAttendees) {
        return true;
      } else if (
        eventAttendees &&
        isOrganizer(eventAttendees, eventCalendarEmail)
      ) {
        return true;
      } else if (getEventGuestPermissions(e, GUESTS_CAN_MODIFY)) {
        return false; // Bug with Google where you can't actually edit events with modify permission
      } else {
        return false;
      }
    } else if (isAvailabilityEvent(e)) {
      return true;
    } else {
      return false;
    }
  };

  const determineOutOfOfficeColor = (color, isDarkMode = false) => {
    if (isDarkMode) {
      return new TinyColor(color).tint(20).shade(40).toHexString();
    }
    return new TinyColor(color).tint(88).toHexString();
  };

  const getCacheToken = (event) => {
    if (isEmptyObjectOrFalsey(event) || !e.uniqueEtag) {
      return "";
    }
    // cache for certain locations
    const { uniqueEtag } = event;

    return where ? `${where}_${uniqueEtag}` : uniqueEtag;
  };

  const determineColor = (event) => {
    const calendarId = getEventUserCalendarID(event) || event.calendarId;

    if (getEventColorID(event) === "0") {
      // get default calendar color
      const calendarColor = determineCalendarColor(
        protectedAllCalendars[calendarId]
      );

      return {
        fadedColor: isOutOfOfficeEvent(event)
          ? determineOutOfOfficeColor(calendarColor)
          : createFadedColorIfIndexDoesNotExist(calendarColor, true),
        normalColor: createFadedColorIfIndexDoesNotExist(calendarColor, false),
      };
    }

    const outlookCategoryColor = determineOutlookCategoryColor({
      allCalendars,
      event,
      outlookCategories,
    });
    if (outlookCategoryColor) {
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(
          outlookCategoryColor,
          true
        ),
        normalColor: outlookCategoryColor,
      };
    } else if (getEventColorHex(event)) {
      const normalColor = getEventColorHex(event);
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(normalColor, true),
        normalColor,
      };
    } else if (event.color) {
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(event.color, true),
        normalColor: event.color,
      };
    } else if (event.backgroundColor) {
      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(
          event.backgroundColor,
          true
        ),
        normalColor: createFadedColorIfIndexDoesNotExist(
          event.backgroundColor,
          false
        ),
      };
    } else if (protectedAllCalendars[calendarId]) {
      // calendarID is part of all the primary and secondary calendars that are logged in
      const eventColorID = getEventColorID(event);
      if (eventColorID) {
        return {
          fadedColor: isOutOfOfficeEvent(event)
            ? determineOutOfOfficeColor(
                GoogleColors.primaryEventsColors[eventColorID].color
              )
            : GoogleColors.primaryEventsColors[eventColorID].fadedColor,
          normalColor: GoogleColors.primaryEventsColors[eventColorID].color,
        };
      } else {
        const calendarColor = determineCalendarColor(
          protectedAllCalendars[calendarId]
        );

        return {
          fadedColor: isOutOfOfficeEvent(event)
            ? determineOutOfOfficeColor(calendarColor)
            : createFadedColorIfIndexDoesNotExist(calendarColor, true),
          normalColor: createFadedColorIfIndexDoesNotExist(
            calendarColor,
            false
          ),
        };
      }
    } else if (isTemporaryEvent(event)) {
      const eventColor =
        event.backgroundColor || DEFAULT_PRIMARY_CALENDAR_COLOR;

      return {
        fadedColor: createFadedColorIfIndexDoesNotExist(eventColor, true),
        normalColor: createFadedColorIfIndexDoesNotExist(eventColor, false),
      };
    } else if (isAvailabilityEvent(event)) {
      return {
        fadedColor: SELECT_AVAILABILITY_COLOR,
        normalColor: SELECT_AVAILABILITY_COLOR,
      };
    } else if (isEventTypeFocusBlock(event)) {
      // return primary calendar user colors
      const matchingCalendar = getCalendarFromEmail(
        getUserEmail(currentUser),
        protectedAllCalendars
      );
      const calendarColor = determineCalendarColor(matchingCalendar);
      return {
        fadedColor: getFadedColor(calendarColor),
        normalColor: calendarColor,
      };
    } else {
      return {
        fadedColor: getFadedColor(DEFAULT_PRIMARY_CALENDAR_COLOR),
        normalColor: DEFAULT_PRIMARY_CALENDAR_COLOR,
      };
    }
  };

  const cacheToken = getCacheToken(e);
  if (
    !e.temporaryCalendarId &&
    !!cacheToken &&
    !!_staticEventIndex[cacheToken]
  ) {
    // do not store static information for temporary events
    return _staticEventIndex[cacheToken];
  }

  // normalColor, fadedColor, onlyPersonAttending, backgroundColor, bordercolor, selfAttendingStatus

  const eventCalendarEmail =
    e.isTemporary || isMeetWithEvent(e)
      ? e.resourceId
      : getEmailBasedOnCalendarId(e, protectedAllCalendars);

  const selfAttendingStatus = getSelfAttendingStatus(e, eventCalendarEmail);
  const { fadedColor, normalColor } = determineColor(e);

  const darkModeFadedColor = isOutOfOfficeEvent(e)
    ? determineOutOfOfficeColor(normalColor, true)
    : dimDarkModeColor(normalColor);
  const shouldTruncateEvent = shouldReduceTextSize(e, 20);

  const result = {
    isEditable: isDisplayEventEditable(eventCalendarEmail),
    eventCalendarEmail: eventCalendarEmail,
    resourceId: getResourceID({
      event: e,
      currentUserEmail: getUserEmail(currentUser),
      eventCalendarEmail,
    }),
    selfAttendingStatus,
    isUserOnlyPersonWhoAcceptedAndRestDeclined:
      isUserOnlyPersonWhoAcceptedAndRestDeclined(e, selfAttendingStatus),
    fadedColor,
    normalColor,
    darkModeFadedColor,
    shouldTruncateEvent,
    userEmail: protectedAllCalendars[getEventUserCalendarID(e)]?.userEmail,
    [RSVP_WITHOUT_SHOW_AS]: getAttendeeStatus(e, eventCalendarEmail),
  };

  if (!e.temporaryCalendarId && !!cacheToken && !isPreviewOutlookEvent(e)) {
    _staticEventIndex[cacheToken] = result;
  }

  return result;
}

export function getResourceID({ event, currentUserEmail, eventCalendarEmail }) {
  if (isMeetWithEvent(event)) {
    return event.resourceId;
  }

  // to see if it's a birthday or holiday calendar
  const isResourceCalendar =
    eventCalendarEmail?.includes("google") &&
    eventCalendarEmail.indexOf("group") > eventCalendarEmail.indexOf("@");
  if (isResourceCalendar) {
    return getEventUserEmail(event) ?? currentUserEmail;
  }

  return eventCalendarEmail;
}

export function getEventStaticInfo({
  event,
  key,
  currentUser,
  currentUserDefaultColor,
  allCalendars,
}) {
  if (event && key in event) {
    return event[key];
  } else {
    /*
      We should never hit this condition since that means we don't have the static information set in the event
     */

    if (!event.start) {
      // if (isTestEnvironment() || isInternalTeamUser(currentUser)) {
      //   console.log("bug_event", event);
      //   console.log("bug_key", key);
      // }
    }
    const staticInfo = createStaticInformation({
      e: event,
      currentUser,
      allCalendars,
      where: "eventFunctions",
    });

    const defaultValues = {
      isEditable: false,
      eventCalendarEmail: getUserEmail(currentUser),
      selfAttendingStatus: true,
      isUserOnlyPersonWhoAcceptedAndRestDeclined: false,
      fadedColor: getFadedColor(currentUserDefaultColor),
      normalColor: currentUserDefaultColor,
      [RSVP_WITHOUT_SHOW_AS]: ATTENDEE_EVENT_ATTENDING,
    };

    return staticInfo[key] ?? defaultValues[key];
  }
}

export function determineMergedEventBackground(colorArray) {
  const filteredArray = colorArray?.filter((c) => c) || [];
  if (filteredArray.length < 2) {
    return "";
  }
  let background = "linear-gradient(90deg, ";
  filteredArray.forEach((c, index) => {
    if (index === filteredArray.length - 1) {
      // last element
      background = background + `${c})`;
    } else {
      background = background + `${c}, `;
    }
  });

  return background;
}

export function determineEventColor({
  event,
  allCalendars,
  user,
  currentUser,
  allLoggedInUsers,
  outlookCategories,
}) {
  const calendarColor = determineCalendarColor(
    allCalendars[getEventUserCalendarID(event)]
  );
  const tagColor = getEventTagColor({
    event,
    user,
    currentUser,
    allLoggedInUsers,
  });
  if (
    isOutlookEvent(event) &&
    shouldUseTagColorAsEventColorForOutlookEvent({
      event,
      user,
      currentUser,
      allLoggedInUsers,
    })
  ) {
    return tagColor; // if there's no tags -> use default color
  }

  if (
    shouldUseTagColorForGoogleEvent({
      event,
      allCalendars,
      user,
      currentUser,
      allLoggedInUsers,
    })
  ) {
    return tagColor;
  }

  const outlookCategoryColor = determineOutlookCategoryColor({
    allCalendars,
    event,
    outlookCategories,
  });
  const eventColorHex = getEventColorHex(event);
  if (outlookCategoryColor) {
    return outlookCategoryColor;
  } else if (eventColorHex) {
    return eventColorHex;
  } else if (event.color) {
    return event.color;
  } else if (getEventColorID(event)) {
    return GoogleColors.primaryEventsColors[getEventColorID(event)].color;
  } else {
    // base event color on calendar
    return calendarColor;
  }
}

export function determineOutlookCategoryColor({
  allCalendars,
  event,
  outlookCategories,
}) {
  if (!allCalendars || !event || !outlookCategories || !isOutlookEvent(event)) {
    return;
  }

  const calendar = getCalendarFromUserCalendarID({
    allCalendars,
    userCalendarID: getEventUserCalendarID(event),
  });
  if (!calendar) {
    return;
  }

  const ownerEmail = getCalendarOwnerEmail(calendar);
  const userCategories = outlookCategories[ownerEmail] ?? [];
  const colorPresetName = userCategories.find(
    (category) => category.displayName === getEventCategories(event)?.[0]
  )?.color;
  if (colorPresetName in OUTLOOK_COLORS) {
    return OUTLOOK_COLORS[colorPresetName].hex;
  }
}

export function clearEventStaticCache() {
  _staticEventIndex = {};
}

export function isOutOfOfficeEvent(e) {
  return getEventType(e) === OUT_OF_OFFICE_EVENT_TYPE;
}

export function createEventHotKeyLabel(events) {
  if (!events || events.length === 0) {
    return {};
  }

  let keyCombinations = GetLetterAndNumberHotKeyCombinations();
  let combinationMapping = {};

  keyCombinations.forEach((k, index) => {
    if (index < events.length) {
      let handler = k[0] + "+" + k[1];
      let sequentialHandler = k[0] + " " + k[1];
      // let combinationHandler = k[0] + '+' + k[1];
      let sequentialHandlerCap = k[0].toUpperCase() + " " + k[1].toUpperCase();
      // let combinationHandlerCap = k[0].toUpperCase() + '+' + k[1].toUpperCase();

      combinationMapping[getEventUserEventID(events[index])] = {
        handler: handler,
        label: k.toUpperCase(),
        event: events[index],
        sequentialHandler,
        sequentialHandlerCap,
      };
    }
  });

  return combinationMapping;
}

const RECLAIM_EVENT_ID = "reclaim.personalSync.eventId";
export function isReclaimEvent(event) {
  return !!getReclaimEventExtendedProperties(event);
}

export function isReclaimPrivateEventTitle(event) {
  if (!isReclaimEvent(event)) {
    return false;
  }
  return ["personal commitment", "busy"].includes(
    lowerCaseAndTrimString(getEventTitle(event))
  );
}

export function getReclaimEventExtendedProperties(event) {
  const eventExtendedProperties = getEventExtendedProperties(event);
  return eventExtendedProperties?.private?.[RECLAIM_EVENT_ID]; // this maps to getGCalEventId(event) or provider_id
}

export function isColorBright(color) {
  return getBrightness(color) > 200;
}

export function dimColorTextColor(isCurrentPreviewEvent, hasEventPassed) {
  if (hasEventPassed) {
    return isCurrentPreviewEvent
      ? "rgb(255,255,255, 0.8)"
      : FADED_GREY_TEXT_COLOR_DARK_MODE;
  }

  return "white";
}

export function brightColorTextColor(
  isCurrentPreviewEvent,
  hasEventPassed,
  isDarkMode
) {
  if (isCurrentPreviewEvent && !hasEventPassed) {
    return isDarkMode
      ? DARK_MODE_BACKGROUND_COLOR
      : StyleConstants.defaultFontColor;
  }

  return isCurrentPreviewEvent || hasEventPassed
    ? "#848383"
    : StyleConstants.defaultFontColor;
}

export function filterEventsInThePast(eventList) {
  if (isEmptyArray(eventList)) {
    return [];
  }

  const NOW = new Date();
  return eventList.filter(
    (e) =>
      (isEventSlotAllDayEvent(e) && isSameOrAfterDay(e.eventEnd, NOW)) ||
      isAfterMinute(e.eventEnd, NOW)
  );
}

export function isEventPrivate(event) {
  return getEventVisibility(event) === PRIVATE;
}

export function getEventReminderOverrides(event) {
  return getEventReminders(event)?.overrides;
}

export function splitEventsByUserCalendarID(eventsList) {
  if (!eventsList || eventsList.length === 0) {
    return {};
  }

  let indexByUserCalendarID = {};
  eventsList.forEach((e) => {
    const eventUserCalendarID = getEventUserCalendarID(e);

    if (indexByUserCalendarID[eventUserCalendarID]) {
      indexByUserCalendarID[eventUserCalendarID] =
        indexByUserCalendarID[eventUserCalendarID].concat(e);
    } else {
      indexByUserCalendarID[eventUserCalendarID] = [e];
    }
  });

  return indexByUserCalendarID;
}

export function getAllUserCalendarIDsFromEventsList(eventsList) {
  if (isEmptyArray(eventsList)) {
    return [];
  }

  return removeDuplicatesFromArray(
    eventsList.map((e) => getEventUserCalendarID(e))
  );
}

export function getDefaultStartDateTime(event) {
  const eventStartDate = getEventStartAllDayDate(event);
  if (eventStartDate) {
    return eventStartDate;
  }

  return getEventStartDateTime(event);
}

export function getDefaultEndDateTime(event) {
  const eventEndDate = getEventEndAllDayDate(event);
  if (eventEndDate) {
    return eventEndDate;
  }

  return getEventEndDateTime(event);
}

export function isCancelledEvent(event) {
  const isCanceled = getEventStatus(event) === SELF_RESPONSE_CANCELLED;
  if (isOutlookEvent(event)) {
    return event?.raw_json?.isCancelled || isCanceled || event?.is_cancelled;
  }
  return isCanceled;
}

export function isAvailabilityEvent(event) {
  return getEventStatus(event) === AVAILABILITY;
}

export function isTemporaryEvent(event) {
  return getEventStatus(event) === TEMPORARY;
}

export function isOrganizerSelf(event) {
  return getEventOrganizer(event)?.self;
}

export function isFreeEvent(event) {
  return getEventTransparency(event) === FREE_DURING_EVENT;
}

export function isBusyEvent(event) {
  return !isFreeEvent(event);
}

export function getEventStartAllDayDate(event) {
  return getEventStart(event)?.date;
}

export function getEventStartDateTime(event) {
  return getEventStart(event)?.dateTime;
}

export function getEventEndAllDayDate(event) {
  return getEventEnd(event)?.date;
}

export function getEventEndDateTime(event) {
  return getEventEnd(event)?.dateTime;
}

// first check for date then check for dateTime
export function getEventStartValue(event) {
  return getEventStartAllDayDate(event) ?? getEventStartDateTime(event);
}

export function getEventEndValue(event) {
  return getEventEndAllDayDate(event) ?? getEventEndDateTime(event);
}

export function getEventStartTimeZone(event) {
  return getEventStart(event)?.timeZone;
}

export function getEventEndTimeZone(event) {
  return getEventEnd(event)?.timeZone;
}

export function getStartTimeUTC(event) {
  return event?.start_time_utc;
}

export function getEndTimeUTC(event) {
  return event?.end_time_utc;
}

export function isValidEvent(event) {
  return !!getEventStart(event);
}

export function hasHumanAttendees(event) {
  return getHumanAttendees(event)?.length > 0;
}

export function getHumanAttendees(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return [];
  }

  const attendees = getEventAttendees(event);
  return filterOutResourceAttendees(attendees);
}

export function isEventAttendeeOrganizer(attendee) {
  return !!attendee?.organizer;
}

export function isEventAttendeeOptional(attendee) {
  return attendee?.optional;
}

function getSelfRSVP(event, email) {
  const attendees = getHumanAttendees(event);
  const selfResponse = attendees.filter((attendee) =>
    isSameEmail(getObjectEmail(attendee), email)
  );
  return selfResponse;
}

function getAttendeeStatus(event, email) {
  const selfResponse = getSelfRSVP(event, email);

  if (isEmptyArray(selfResponse)) {
    return ATTENDEE_EVENT_ATTENDING;
  }

  return selfResponse.length >= 1
    ? selfResponse[0].responseStatus
    : NO_RESPONSE_NEEDED;
}

export function getSelfRSVPComment(event, email) {
  const selfResponse = getSelfRSVP(event, email);
  return getEventAttendeeComment(selfResponse?.[0]);
}

// Note: this is different than getSelfAttendingStatus because this only checks for RSVP and not outlook's show_as property
export function getSelfRSVPStatus(event, email) {
  return getAttendeeStatus(event, email);
}

export function getSelfAttendingStatus(event, email) {
  if (isOutlookShowAsTentativeEvent(event)) {
    // this is when outlook overrides
    return ATTENDEE_EVENT_TENTATIVE;
  }
  return getAttendeeStatus(event, email);
}

export function eventHasAttendees(event) {
  return getEventAttendees(event)?.length > 0;
}

export function isFocusModeEvent(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  const { summaryUpdatedWithVisibility } = event;

  if (!summaryUpdatedWithVisibility) {
    return false;
  }

  const loweredCase = summaryUpdatedWithVisibility.toLowerCase();

  if (
    loweredCase.includes("focus") ||
    loweredCase.includes("deep") ||
    loweredCase.includes("work") ||
    loweredCase.includes("block")
  ) {
    return true;
  }
}

export function isEventTypeFocusBlock(event) {
  return getEventStatus(event) === TYPE_FOCUS_BLOCK;
}

export function isOutlookEvent(event) {
  return getEventProvider(event) === CALENDAR_PROVIDERS.OUTLOOK;
}

export function isGoogleEvent(event) {
  return (
    getEventProvider(event) === CALENDAR_PROVIDERS.GOOGLE ||
    getEventProvider(event) !== CALENDAR_PROVIDERS.OUTLOOK
  );
}

export function isAllDayEvent(event) {
  if (isOutlookEvent(event)) {
    return isAllDayOutlookEvent(event);
  }

  return !!getEventEndAllDayDate(event);
}

export function getEventStartAndEnd(event) {
  const isAllDay = isAllDayEvent(event);

  if (isOutlookEvent(event) && !isAllDay) {
    const jsStart = parseJSON(getEventStartValue(event));
    const jsEnd = parseJSON(getEventEndValue(event));

    return { eventStart: jsStart, eventEnd: jsEnd };
  }
  if (isOutlookEvent(event) && isAllDay) {
    // outlook all day events as passed back as "2023-07-18T00:00:00.0000000"
    // so for certain times of the day, the event will be off by a day
    return parseOutlookAllDayEvent(event);
  } else {
    const jsStart = parseISO(getEventStartValue(event));
    const jsEnd = parseISO(getEventEndValue(event));
    return { eventStart: jsStart, eventEnd: jsEnd };
  }
}

export function formatEventsArrayForReactBigCalendar({
  events,
  currentTimeZone,
  calendarId,
  isPreviewOutlookEvent,
}) {
  if (isEmptyArray(events)) {
    // used to prevent this error: https://vimcal.sentry.io/issues/4317942840/?project=2190664&query=typeerror&referrer=issue-stream&statsPeriod=14d&stream_index=5
    return [];
  }

  return events.map((e) =>
    formatEventForReactBigCalendar({
      event: e,
      currentTimeZone,
      calendarId,
      isPreviewOutlookEvent,
    })
  );
}

export function eventOnlyHasTime(event) {
  if (
    !getEventLocation(event) &&
    !event?.conferenceUrl &&
    generateConferenceRooms(event).length === 0
  ) {
    return true;
  }

  return false;
}

export function getEventDuration(event) {
  const { eventStart, eventEnd } = event;
  return differenceInMinutes(eventEnd, eventStart);
}

export function isOutlookNonOrganizer(event) {
  if (!event || !isOutlookEvent(event)) {
    return false;
  }

  const isOutlook = isOutlookEvent(event);
  const attendees = getEventAttendees(event);
  const hasAttendees = Array.isArray(attendees) && attendees.length > 0;
  const isOrganizer = isOrganizerSelf(event);

  return isOutlook && hasAttendees && !isOrganizer;
}

export function isLocationString(event) {
  const location = getEventLocation(event);
  return typeof location === "string";
}

export function showChangesWillOnlyShowOnThisCalendar({
  event,
  allCalendars,
  currentUser,
}) {
  const determineCalendarFromCalendarId = (calendarId) => {
    const allWritableCalendars = getAllEditableCalendars(allCalendars);

    if (allWritableCalendars[calendarId]) {
      return allWritableCalendars[calendarId];
    } else {
      return getCalendarFromEmail(currentUser?.email, allCalendars);
    }
  };

  const calendar = determineCalendarFromCalendarId(
    getEventUserCalendarID(event)
  );

  const eventOrganizerEmail = getEventOrganizer(event)?.email;
  const calendarEmail = isVersionV2()
    ? calendar?.provider_id
    : calendar?.google_id;

  if (
    getEventMasterEventID(event) &&
    getEventAttendees(event)?.length > 0 &&
    calendarEmail &&
    eventOrganizerEmail &&
    eventOrganizerEmail !== calendarEmail
  ) {
    return true;
  }

  return !hasPermissionToModify(event, allCalendars);
}

export function shouldDisplayEvent({ showDeclinedEvents, event, email }) {
  if (!isValidEvent(event) || isCancelledEvent(event)) {
    return false;
  }

  const attendingStatus = getSelfAttendingStatus(event, email);

  if (attendingStatus !== GoogleCalendarService.attendee_event_declined) {
    return true;
  }
  // below is logic for when we want to show declined events

  // Outlook events are always shown
  if (isOutlookEvent(event)) {
    return false;
  }

  // for google events
  return showDeclinedEvents;
}

export function shouldShowProposeTime({ event, allCalendars }) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  if (isOutlookEvent(event)) {
    return false;
  }
  const attendees = getEventAttendees(event);
  if (!attendees || attendees.length === 0) {
    return false;
  }

  // only show if it's an organizer
  const eventCalendarEmail = getEmailBasedOnCalendarId(event, allCalendars);
  return !isOrganizer(attendees, eventCalendarEmail);
}

export const formatContactsToAttendees = ({
  allLoggedInUsers = [],
  calendar = null,
  contacts = [],
}) => {
  let contactsList = contacts.map((contact) => {
    const email = contact.email || contact.contact?.email;
    const name = contact.name || contact.contact?.name;

    return {
      displayName: name,
      email,
      responseStatus: "needsAction",
    };
  });

  if (
    !isEmptyObjectOrFalsey(calendar) &&
    getCalendarUserEmail(calendar) &&
    allLoggedInUsers?.length > 0
  ) {
    const calendarUserEmail = getCalendarUserEmail(calendar);
    const organizerUser = allLoggedInUsers.find(
      (u) => u.email === calendarUserEmail
    );

    const organizerAttendee = {
      displayName: getSelectedUserName({ user: organizerUser }).fullName,
      email: calendarUserEmail,
      responseStatus: "accepted",
      isOrganizer: true,
    };

    contactsList.unshift(organizerAttendee);
  }

  return contactsList;
};

export function removeDuplicateEventsBasedOnTime(events) {
  const uniqueEvents = events.filter((event, index) => {
    const firstIndex = events.findIndex((otherEvent) => {
      return (
        event.eventStart.getTime() === otherEvent.eventStart.getTime() &&
        event.rbcEventEnd.getTime() === otherEvent.rbcEventEnd.getTime()
      );
    });
    return firstIndex === index;
  });
  return uniqueEvents;
}

export function getEventAttendeeDomains({ event, allLoggedInUsers }) {
  const attendees = getEventAttendees(event);
  if (isEmptyArray(attendees)) {
    return [];
  }

  const uniqueDomains = removeDuplicatesFromArray(
    attendees.map((attendee) => {
      const email = attendee.email;
      return getEmailDomain(email)?.toLowerCase().trim() ?? "";
    })
  );

  // filter out domains that are the same as the current user and also excluded domains (google, gmail, etc)
  return filterOutCompanyDomains({ domains: uniqueDomains, allLoggedInUsers });
}

// filter out domains that are logged in and gmail, yahoo, etc
function filterOutCompanyDomains({ domains, allLoggedInUsers }) {
  const userDomains = removeDuplicatesFromArray(
    allLoggedInUsers.map((attendee) => {
      const email = attendee.email;
      return getEmailDomain(email)?.toLowerCase().trim() ?? "";
    })
  );

  const allExcludedDomains = removeDuplicatesFromArray(
    userDomains.concat(EXCLUDED_DOMAINS)
  );
  return domains.filter((url) => {
    // Check if the current URL includes any of the excluded URLs
    return !allExcludedDomains.some((excludedUrl) => url.includes(excludedUrl));
  });
}

// events that are too long (e.g. 50000 years) will break app (infinite loop and take too much compute)
// since we iterate over each day when putting the event into the index
export function isEventTooLong(e) {
  if (!e?.eventStart || !e?.eventEnd) {
    return true;
  }

  const { eventStart, eventEnd } = e;
  return differenceInMonths(eventEnd, eventStart) > 1;
}

export function isEventWithinTimeRange({
  event,
  timeRangeStart,
  timeRangeEnd,
}) {
  const { eventStart, eventEnd } = event;

  return (
    isSameOrBeforeMinute(eventStart, timeRangeEnd) &&
    isSameOrAfterMinute(eventEnd, timeRangeStart)
  );
}

export function isEventWithinDateRange({
  event,
  timeRangeStart,
  timeRangeEnd,
}) {
  const { eventStart, eventEnd } = event;

  return (
    isSameOrBeforeMinute(eventStart, endOfDay(timeRangeEnd)) &&
    isSameOrAfterMinute(eventEnd, startOfDay(timeRangeStart))
  );
}

export function isEventWithinExactTimeRange({
  event,
  timeRangeStart,
  timeRangeEnd,
}) {
  const { eventStart, eventEnd } = event;

  return (
    isSameOrBeforeMinute(eventStart, timeRangeEnd) &&
    isSameOrAfterMinute(eventEnd, timeRangeStart)
  );
}

// get the searched email of the meet with event
export function getMeetWithEventCalendarEmail(event) {
  return event?.temporaryCalendarId;
}

export function getEventCalendarProviderID(event) {
  return event?.calendar_provider_id;
}

export function isEventHiddenAttendees(event) {
  if (getEventGuestPermissions(event, GUESTS_CAN_MODIFY)) {
    return false;
  }

  return getEventGuestPermissions(event, GUESTS_CAN_SEE_OTHER_GUESTS) === false;
}

export function doesEventSpanMultipleDays(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }
  const { eventStart, eventEnd } = event;

  if (!eventStart || !eventEnd) {
    return false;
  }

  return !isSameDay(eventStart, eventEnd);
}

export function getEventShowAs(event) {
  return getOutlookEventShowAs(event) || event?.show_as;
}

const EVENT_SHOW_AS_TYPES = {
  DEFAULT: "default",
  OUT_OF_OFFICE: "outOfOffice",
  FOCUS_TIME: "focusTime",
  WORKING_LOCATION: "workingLocation",
};

export function isWorkplaceEvent(event) {
  return getEventShowAs(event) === EVENT_SHOW_AS_TYPES.WORKING_LOCATION;
}

// {
//   "type": "officeLocation",
//   "officeLocation": {}
// }
export function getEventWorkLocationData(event) {
  return (
    event.working_location_properties ??
    event?.raw_json?.workingLocationProperties
  );
}

const WORK_LOCATION_TYPE = {
  HOME_OFFICE: "homeOffice", // The user is working at home.
  OFFICE_LOCATION: "officeLocation", // The user is working from an office.
  CUSTOM_LOCATION: "customLocation", // The user is working from a custom location.
};

export function getEventWorkLocationType(event) {
  return getEventWorkLocationData(event)?.type;
}

export function isEventWorkLocationHome(event) {
  return getEventWorkLocationType(event) === WORK_LOCATION_TYPE.HOME_OFFICE;
}

export function isEventWorkLocationOffice(event) {
  return getEventWorkLocationType(event) === WORK_LOCATION_TYPE.OFFICE_LOCATION;
}

export function isEventWorkLocationCustom(event) {
  return getEventWorkLocationType(event) === WORK_LOCATION_TYPE.CUSTOM_LOCATION;
}

export function isSpecialEvent(event) {
  return isWorkplaceEvent(event) || isOutOfOfficeEvent(event);
}

export function isAllDayWorkLocationEvent(event) {
  return isAllDayEvent(event) && isWorkplaceEvent(event);
}

/**
 * Return some unique identifier for the given event to be used as a key.
 * Do not create a UUID as a fallback, as the return value of this function
 * needs to be deterministic.
 */
export function getEventUniqueKey(event) {
  // When editing an event, there could be a brief moment where both the
  // event and the temporary event are rendered simultaneously, so we
  // need to differentiate the keys.
  const suffix = isTemporaryEvent(event) ? "-temp" : "";
  return (event?.user_event_id ?? event?.id) + suffix;
}

export function isDeclinedEvent(selfAttendingStatus) {
  return selfAttendingStatus === ATTENDEE_EVENT_DECLINED;
}

/* Should only be used on Outlook events */
/* Will need to update for Google if same functionality is allowed */
export function appendInlineAttachments({
  attachments,
  description,
  isDescriptionBlank,
}) {
  /* Description just filled with empty HTML -> return empty string */
  if (isEmptyArray(attachments) && isDescriptionBlank) {
    return "";
  }

  let updatedDescription = description;
  attachments
    .filter((attachment) => attachment.isInline)
    .forEach((attachment) => {
      const { contentBytes, contentId, contentType } = attachment;

      /* Replace the source of the inline attachments with content */
      updatedDescription = updatedDescription.replace(
        `cid:${contentId}`,
        `data:${contentType};base64,${contentBytes}`
      );
    });

  return updatedDescription;
}

export function isPreviewOutlookEvent(event) {
  return event?.isPreviewOutlookEvent;
}

export function getUniquePreviewOutlookEvents(events) {
  if (isEmptyArray(events)) {
    return [];
  }
  const eventIDSet = new Set();
  return events.filter((event) => {
    const eventID = getEventID(event);
    if (eventIDSet.has(eventID)) {
      return false;
    } else {
      eventIDSet.add(eventID);
      return true;
    }
  });
}

export function filterOutInternalEvents({events, internalDomains}) {
  if (isEmptyArray(events)) {
    return [];
  }

  if (isEmptyArray(internalDomains)) {
    return events;
  }

  return events.filter((event) => {
    const attendees = getHumanAttendees(event);
    if (isEmptyArray(attendees)) {
      return false; // if there's no attendees, filter out
    }

    return attendees.some((attendee) => {
      const email = getObjectEmail(attendee);
      const domain = getEmailDomain(email);
      // Check if either the entire email or the domain is in internalDomains
      return !internalDomains.includes(email) && !internalDomains.includes(domain);
    });
  });
}

export function getConferenceDataConferenceName(conferenceData) {
  return conferenceData?.conferenceSolution?.name;
}

export function isConferenceDataZoom(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("zoom");
}

export function isConferenceDataPhone(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("phone call");
}

export function isConferenceDataGoogleHangoutMeet(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("google");
}

export function isConferenceDataWhatsApp(conferenceData) {
  return getConferenceDataConferenceName(conferenceData)?.toLowerCase().includes("whatsapp");
}

export function getEventDescriptionUIClassnames({sanitizedDescription, event, userEmail}) {
  const getWrapperClassName = () => {
    if (sanitizedDescription?.includes("Google Calendar")) {
      return "google-invite";
    }
    if (sanitizedDescription?.includes("BCG")) {
      return "bcg-container";
    }
    return "word-break-break-word";
  };

  const skipFormattingDescriptionForOutlookDarkMode = () => {
    const eventDescription = getEventDescription(event) ?? "";
    if (!eventDescription) {
      return false;
    }
    if (isWBEmail(userEmail)) {
      return true;
    }
    const loweredCaseEventDescription = lowerCaseAndTrimStringWithGuard(eventDescription);
    return (
      loweredCaseEventDescription.includes("bcg.com") ||
      loweredCaseEventDescription.includes("wb.com") ||
      loweredCaseEventDescription.includes("warner") ||
      loweredCaseEventDescription.includes("google calendar")
    );
  };

  return classNames(
    "expand-event-summary",
    getWrapperClassName(),
    isOutlookEvent(event) &&
      !skipFormattingDescriptionForOutlookDarkMode()
      ? "outlook-expanded-event-description"
      : "",
  );
}

export function getSanitizedEventDescription(event) {
  const eventDescription = getEventDescription(event);
  if (!eventDescription) {
    return "";
  }
  const text = replaceStringInLinkTag(eventDescription);
  return sanitizeStringAndLinkify(text);
}

export function getEventVisibilityOptions(event) {
  if (isOutlookEvent(event)) {
    return OUTLOOK_EVENT_VISIBILILITY_OPTIONS;
  }
  return GOOGLE_EVENT_VISIBILILITY_OPTIONS;
}

export function getCalendarVisibilityOptions(calendar) {
  if (isCalendarOutlookCalendar(calendar)) {
    return OUTLOOK_EVENT_VISIBILILITY_OPTIONS;
  }
  return GOOGLE_EVENT_VISIBILILITY_OPTIONS;
}
