import create from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";
import { CALENDAR_PROVIDERS } from "../../lib/vimcalVariables";

interface IsMouseMovingState {
  isMouseMoving: boolean
  setIsMouseMoving: (isMouseMoving: boolean) => void
}

export const useIsMouseMoving = create(
  subscribeWithSelector<IsMouseMovingState>((set) => ({
    isMouseMoving: false,
    setIsMouseMoving: (isMouseMoving) => set((_state) => ({ isMouseMoving })),
  })),
);

interface TabIDState {
  tabID: string | null
  setTabID: (tabID: string) => void
}

export const useTabID = create(
  persist<TabIDState>(
    (set) => ({
      tabID: null,
      setTabID: (tabID) => {
        set((_state) => ({ tabID }));
      },
    }),
    {
      name: "tab-id-storage",
    },
  ),
);

interface AppTimeZonesState {
  orderedTimeZones: string[]
  setOrderedTimeZones: (orderedTimeZones: string[]) => void
  lastSelectedTimeZone: string | null
  lastOrderedTimeZonesUpdatedAt: string | null
  setLastSelectedTimeZone: (lastSelectedTimeZone: string) => void
  resetLastSelectedTimeZone: () => void
  resetTimeZoneData: () => void
}

// way to track what the user selected as the left most time zone
export const useAppTimeZones = create(
  persist<AppTimeZonesState>(
    (set) => ({
      orderedTimeZones: [],
      setOrderedTimeZones: (orderedTimeZones) => {
        set((_state) => ({
          orderedTimeZones,
          lastOrderedTimeZonesUpdatedAt: new Date().toISOString(),
        }));
      },
      lastSelectedTimeZone: null,
      lastOrderedTimeZonesUpdatedAt: null,
      setLastSelectedTimeZone: (lastSelectedTimeZone) => {
        set((_state) => ({ lastSelectedTimeZone }));
      },
      resetLastSelectedTimeZone: () => {
        set((_state) => ({ lastSelectedTimeZone: null }));
      },
      resetTimeZoneData: () => {
        set((_state) => ({
          lastSelectedTimeZone: null,
          orderedTimeZones: [],
          lastOrderedTimeZonesUpdatedAt: null,
        }));
      },
    }),
    {
      name: "time-zone-storage",
    },
  ),
);

interface HideRightHandSidebarState {
  shouldHideRightHandSide: boolean
  setHideRightHandSide: (shouldHide: boolean) => void
  resetRightHandSidebar: () => void
}

export const useHideRightHandSidebar = create(
  persist<HideRightHandSidebarState>(
    (set) => ({
      shouldHideRightHandSide: false,
      setHideRightHandSide: (shouldHide) => {
        mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT"); // remove all the preview events

        set((_state) => ({ shouldHideRightHandSide: shouldHide }));
      },
      resetRightHandSidebar: () =>
        set((_state) => ({ shouldHideRightHandSide: false })),
    }),
    {
      name: "hide-right-hand-side-storage",
    },
  ),
);

export interface ReferralState {
  hasShownReferBoost: boolean
  isInReferBoost: boolean
  stopReferBoost: () => void
  setHasShownReferBoost: () => void
  resetReferralStore: () => void
}

export const useReferralStore = create(
  persist<ReferralState>(
    (set) => ({
      hasShownReferBoost: false,
      isInReferBoost: false,
      stopReferBoost: () => {
        set((_state) => ({
          isInReferBoost: false,
        }));
      },
      setHasShownReferBoost: () => {
        set((_state) => ({
          hasShownReferBoost: true,
          isInReferBoost: true,
        }));
      },
      resetReferralStore: () => {
        set(() => ({
          hasShownReferBoost: false,
          isInReferBoost: false,
        }));
      },
    }),
    {
      name: "referral-storage",
    },
  ),
);

export const SLOTS_SECTIONS = {
  STYLING: "STYLING",
  HOLDS: "HOLDS",
  PERSONALIZE_INVITE: "PERSONALIZE_INVITE",
  MEETING_DETAILS: "MEETING_DETAILS",
} as const;

interface AccountActivityState {
  hasReceivedLoginCode: boolean
  isStillPendingInitialCalendarSync: boolean
  setHasReceivedLoginCode: () => void
  onReceiveInitialSync: () => void
  resetAccountActivity: () => void
  lastClickedLoginButton: ValueOf<typeof CALENDAR_PROVIDERS> | null
  setLastClickedLoginButton: (lastClickedLoginButton: ValueOf<typeof CALENDAR_PROVIDERS>) => void
  slotsDefaultOpenSections: ValueOf<typeof SLOTS_SECTIONS>[],
  setSlotsDefaultOpenSections: (slotsDefaultOpenSections: ValueOf<typeof SLOTS_SECTIONS>[]) => void
}

export const useAccountActivity = create(
  persist<AccountActivityState>((set) => ({
    hasReceivedLoginCode: false,
    isStillPendingInitialCalendarSync: true,
    setHasReceivedLoginCode: () => {
      set((_state) => ({
        hasReceivedLoginCode: true,
      }));
    },
    onReceiveInitialSync: () => {
      set((_state) => ({
        isStillPendingInitialCalendarSync: false,
        hasReceivedLoginCode: false,
      }));
    },
    resetAccountActivity: () => {
      set(() => ({
        hasReceivedLoginCode: false,
        isStillPendingInitialCalendarSync: true,
        lastClickedLoginButton: null,
      }));
    },
    lastClickedLoginButton: null, // google or outlook, use CALENDAR_PROVIDERS enum
    setLastClickedLoginButton: (lastClickedLoginButton) => {
      set((_state) => ({ lastClickedLoginButton }));
    },
    slotsDefaultOpenSections: [],
    setSlotsDefaultOpenSections: (slotsDefaultOpenSections) => {
      set((_state) => ({ slotsDefaultOpenSections }));
    },
  }), {
    name: "account-activity-storage", // unique name of the store
    partialize: (state) => ({
      lastClickedLoginButton: state.lastClickedLoginButton,
      slotsDefaultOpenSections: state.slotsDefaultOpenSections,
    }), // only persist lastClickedLoginButton and slotsDefaultOpenSections
  }),
);

interface TutorialWizardState {
  hasRemovedTutorialWizard: boolean
  isTutorialWizardShowing: boolean
  isTutorialWizardMinimized: boolean
  expandTutorialWizard: () => void
  minimizeTutorialWizard: () => void
  showMinifiedTutorialWizard: () => void
  showTutorialWizard: () => void
  removeTutorialWizard: () => void
  resetTutorialWizard: () => void
}

export const useTutorialWizard = create(
  persist<TutorialWizardState>(
    (set) => ({
      hasRemovedTutorialWizard: false,
      isTutorialWizardShowing: false,
      isTutorialWizardMinimized: true,
      expandTutorialWizard: () => {
        set((_state) => ({
          isTutorialWizardMinimized: false,
        }));
      },
      minimizeTutorialWizard: () => {
        set((_state) => ({
          isTutorialWizardMinimized: true,
        }));
      },
      showMinifiedTutorialWizard: () => {
        set((_state) => ({
          isTutorialWizardShowing: true,
          isTutorialWizardMinimized: true,
        }));
      },
      showTutorialWizard: () => {
        set((_state) => ({
          isTutorialWizardShowing: true,
          isTutorialWizardMinimized: false,
        }));
      },
      removeTutorialWizard: () => {
        set((_state) => ({
          isTutorialWizardShowing: false,
          isTutorialWizardMinimized: true,
          hasRemovedTutorialWizard: true,
        }));
      },
      resetTutorialWizard: () => {
        set((_state) => ({
          isTutorialWizardShowing: false,
          isTutorialWizardMinimized: true,
          hasRemovedTutorialWizard: false,
        }));
      },
    }),
    {
      name: "tutorial-wizard-storage",
    },
  ),
);

interface FeatureFlagsState {
  shouldShowFindTime: boolean
  setShouldShowFindTime: () => void
  shouldShowRewind2023: boolean
  showRewind2023: () => void
  hideRewind2023: () => void
  resetFeatureFlags: () => void
}

export const useFeatureFlags = create(
  persist<FeatureFlagsState>(
    (set) => ({
      shouldShowFindTime: false,
      setShouldShowFindTime: () => {
        set((_state) => ({
          shouldShowFindTime: true,
        }));
      },
      shouldShowRewind2023: false,
      showRewind2023: () => {
        set((_state) => ({
          shouldShowRewind2023: true,
        }));
      },
      hideRewind2023: () => {
        set((_state) => ({
          shouldShowRewind2023: false,
        }));
      },
      resetFeatureFlags: () => {
        set((_state) => ({
          shouldShowRewind2023: false,
        }));
      },
    }),
    {
      name: "flag-storage",
    },
  ),
);
