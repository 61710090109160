import Dexie from "dexie";
import {
  removeDuplicatesFromArray,
  handleError,
  getStartOfDayUTC,
  getEndOfDayUTC,
} from "./commonUsefulFunctions";
import { getCurrentUserEmail } from "../lib/localData";
import {
  getEndTimeUTC,
  getStartTimeUTC,
  isAllDayEvent,
} from "../lib/eventFunctions";
import {
  getEventMasterEventID,
  getEventUserCalendarID,
} from "./eventResourceAccessors";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";
import { getObjectEmail } from "../lib/objectFunctions";

class DexieDB {
  "use strict";

  constructor() {
    this.emailDB = {};
  }

  resetEmailDBIndex() {
    this.emailDB = {};
  }

  fetch(inputEmail = null) {
    const email = inputEmail || getCurrentUserEmail() || "default_user";

    if (this.emailDB[email] && this.emailDB[email].isOpen()) {
      return this.emailDB[email];
    } else {
      try {
        const newDB = new Dexie(email);
        newDB.version(1).stores({
          events:
            "&user_event_id, calendarId, date, *summary, *attendees, *location, event",
          contacts:
            "&email, *fullName, name, updated, firstName, lastName, primary, accountPrimaryEmail, userEmail",
          domainUsers: "&email, *fullName, name, updated",
          conferenceRooms:
            "&resourceEmail, *resourceName, userVisibilityDescription, roomsObject, buildingId, fullName, capacity",
          buildings:
            "&domainBuildingId, *buildingId, *buildingName, buildingObject, fullName",
          hashKey: "&key",
        });
        newDB.version(2).stores({
          groups:
            "&googleID, email, name, description, aliases, *nonEditableAliases, *googleGroupMembers, directMembersCount",
        });
        newDB.version(3).stores({
          conferenceRooms:
            "&resourceEmail, *resourceName, userVisibilityDescription, roomsObject, buildingId, fullName, capacity, features",
        });

        this.emailDB[email] = newDB;

        return this.emailDB[email];
      } catch (error) {
        handleError(error);
      }
    }
  }

  async singleAccountLogout(email) {
    if (!email) {
      return;
    }

    try {
      const stores = await Dexie.getDatabaseNames();
      if (stores.includes(email)) {
        await this.wipeOutAccountStore(email);
      }
    } catch (error) {
      handleError(error);
    }
  }

  async logOut(dbsToDelete = []) {
    const stores = await Dexie.getDatabaseNames();
    let dexieDBs = [];
    if (!isEmptyObjectOrFalsey(this.emailDB)) {
      dexieDBs = dexieDBs.concat(Object.keys(this.emailDB));
    }

    if (dbsToDelete?.length > 0) {
      dexieDBs = dexieDBs.concat(dbsToDelete.map((d) => getObjectEmail(d)));
    }

    dexieDBs = removeDuplicatesFromArray(dexieDBs);

    for (const email of dexieDBs) {
      if (stores.includes(email)) {
        await this.wipeOutAccountStore(email);
      }
    }
  }

  async wipeOutAccountStore(email) {
    const db = this.fetch(email);
    if (!db) {
      return;
    }

    try {
      await db.delete();
    } catch (error) {
      handleError(error);
    }
  }

  // timeMin, timeMax are both js dates
  getTimeMaxAndMinString({ timeMin, timeMax }) {
    const timeMinUTC = timeMin.toISOString();
    const timeMaxUTC = timeMax.toISOString();

    const allDayTimeMinUTC = getStartOfDayUTC(timeMin).toISOString();
    const allDayTimeMaxUTC = getEndOfDayUTC(timeMax).toISOString();
    return {
      timeMinUTC,
      timeMaxUTC,
      allDayTimeMinUTC,
      allDayTimeMaxUTC,
    };
  }

  /// returns db event object with is {event, user_event_id, ...}
  getEventsFromDate({ email, timeMin, timeMax }) {
    if (!timeMin || !timeMax) {
      return;
    }

    let db = this.fetch(email);
    if (!db) {
      return;
    }
    const { timeMinUTC, timeMaxUTC, allDayTimeMinUTC, allDayTimeMaxUTC } =
      this.getTimeMaxAndMinString({ timeMin, timeMax });

    const eventsWithinDate = db.events
      .filter((dbEvent) => {
        if (!dbEvent?.event) {
          return false;
        }
        const { event } = dbEvent;
        if (isAllDayEvent(event)) {
          return (
            getStartTimeUTC(event) <= allDayTimeMaxUTC &&
            getEndTimeUTC(event) >= allDayTimeMinUTC
          );
        }
        return (
          getStartTimeUTC(event) <= timeMaxUTC &&
          getEndTimeUTC(event) >= timeMinUTC
        );
      })
      .toArray();

    return eventsWithinDate;
  }

  async deleteRecurringEvents({
    email,
    masterEventID,
    originalEvent, // for deleting this and following
  }) {
    if (!email || !masterEventID) {
      return;
    }
    const db = this.fetch(email);
    const originalEventStartTimeUTC = getStartTimeUTC(originalEvent);
    if (!originalEventStartTimeUTC) {
      // delete all instances of this recuring event
      try {
        const matchingEventIDs = await db.events
          .filter((dBEvent) => {
            if (!dBEvent?.event) {
              return false;
            }
            const { event } = dBEvent;
            return getEventMasterEventID(event) === masterEventID;
          })
          .primaryKeys(); // Use .primaryKeys() to get an array of the primary keys
        return db.events.bulkDelete(matchingEventIDs);
      } catch (error) {
        handleError(error);
      }
    }

    // for this and following
    try {
      const matchingEventIDs = await db.events
        .filter((dBEvent) => {
          if (!dBEvent?.event) {
            return false;
          }
          const { event } = dBEvent;
          return (
            getEventMasterEventID(event) === masterEventID &&
            getStartTimeUTC(event) >= originalEventStartTimeUTC
          );
        })
        .primaryKeys(); // Use .primaryKeys() to get an array of the primary keys

      return db.events.bulkDelete(matchingEventIDs);
    } catch (error) {
      handleError(error);
    }
  }

  async wipeOutEventsWithMatchingUserCalendarIDs({
    email,
    timeMin,
    timeMax,
    userCalendarIDs,
  }) {
    if (!timeMin || !timeMax || isEmptyArrayOrFalsey(userCalendarIDs)) {
      return;
    }
    const db = this.fetch(email);

    try {
      const { timeMinUTC, timeMaxUTC, allDayTimeMinUTC, allDayTimeMaxUTC } =
        this.getTimeMaxAndMinString({ timeMin, timeMax });

      const matchingEventIDs = await db.events
        .filter((dbEvent) => {
          if (!dbEvent?.event) {
            return false;
          }
          const { event } = dbEvent;
          if (!userCalendarIDs.includes(getEventUserCalendarID(event))) {
            return false;
          }
          if (isAllDayEvent(event)) {
            return (
              getStartTimeUTC(event) <= allDayTimeMaxUTC &&
              getEndTimeUTC(event) >= allDayTimeMinUTC
            );
          }
          return (
            getStartTimeUTC(event) <= timeMaxUTC &&
            getEndTimeUTC(event) >= timeMinUTC
          );
        })
        .primaryKeys(); // Use .primaryKeys() to get an array of the primary keys

      return db.events.bulkDelete(matchingEventIDs);
    } catch (error) {
      handleError(error);
    }
  }

  async wipeOutEventsOnRefresh({ email, timeMin, timeMax }) {
    if (!timeMin || !timeMax) {
      return;
    }
    const db = this.fetch(email);

    try {
      const { timeMinUTC, timeMaxUTC, allDayTimeMinUTC, allDayTimeMaxUTC } =
        this.getTimeMaxAndMinString({ timeMin, timeMax });
      const matchingEventIDs = await db.events
        .filter((dbEvent) => {
          if (!dbEvent?.event) {
            return false;
          }
          const { event } = dbEvent;
          if (isAllDayEvent(event)) {
            return (
              getStartTimeUTC(event) <= allDayTimeMaxUTC &&
              getEndTimeUTC(event) >= allDayTimeMinUTC
            );
          }
          return (
            getStartTimeUTC(event) <= timeMaxUTC &&
            getEndTimeUTC(event) >= timeMinUTC
          );
        })
        .primaryKeys();

      return db.events.bulkDelete(matchingEventIDs);
    } catch (error) {
      handleError(error);
    }
  }

  wipeOutConferenceRoomsAndBuildings(email) {
    const db = this.fetch(email);

    try {
      return [db.conferenceRooms.clear(), db.buildings.clear()];
    } catch (error) {
      handleError(error);
    }
  }
}

const db = new DexieDB();
export default db;
