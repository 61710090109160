import { isOrganizer } from "../services/eventResourceAccessors";
import { hasHumanAttendees, isOrganizerSelf } from "./eventFunctions";

export const DEFAULT_TO_USE_ZOOM_PERSONAL_MEETING_LINK =
  "Default to using Personal Meeting Link";
export const PERSONAL_ZOOM_LINK_INPUT_LABEL = "Personal Meeting Link";
export const PERSONAL_LINK_COPY = {
  ADD_TO_WEBSITE:
    "Embed this booking link into your website by pasting the following code into your site's HTML.",
};
export const YC_DISCOUNT_COPY =
  "Remember, you get 50% off for the first six months!";
export const ADMINS_DAY_DISCOUNT_COPY =
  "Remember, you get 50% off for the first year!";
export const SLOTS_IN_BUFFER_BEFORE_CONFLICTS = {
  title: "Buffer before conflicts blocking Slots",
  subText:
    "Selected Slots will be unavailable due to buffer before conflicts. To change this setting, please go to Slots settings.",
};
export const SLOTS_IN_BUFFER_AFTER_CONFLICTS = {
  title: "Buffer after conflicts blocking Slots",
  subText:
    "Selected Slots will be unavailable due to buffer after conflicts. To change this setting, please go to Slots settings.",
};
export const WINBACK_DISCOUNT_COPY = "Remember, you have $15 off!";

export function getDeleteEventCopy({ event, alwaysShowEventText = false }) {
  if (!isOrganizer(event) && !isOrganizerSelf(event)) {
    if (alwaysShowEventText) {
      return "Delete event";
    }
    return "Delete";
  }

  if (alwaysShowEventText) {
    return hasHumanAttendees(event) ? "Cancel event" : "Delete event";
  }
  return hasHumanAttendees(event) ? "Cancel event" : "Delete";
}

export const SHOW_EVERY_SELECTED_TIME_ZONE_COPY = "Show every time zone";
export const GUESSED_TIME_ZONE_COPY = "Time zone guessed from local time. Adjust if needed.";
export const GUESSED_TIME_ZONES_ON_APPLY_ALL_COPY = "Time zones guessed from local time. Adjust if needed.";

export const PERSONAL_LINK_NO_BLOCKING_CALENDARS_COPY = {
  TITLE: "No calendars set to check for conflicts",
  SUBTEXT:
    "Add a calendar to avoid double-booking by ensuring meetings aren't scheduled during conflicting times.",
};
