import { addDays, format, isSameDay } from "date-fns";
import appBroadcast from "../broadcasts/appBroadcast";
import backendBroadcasts from "../broadcasts/backendBroadcasts";
import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import settingsBroadcast from "../broadcasts/settingsBroadcast";
import updateSettingsBroadcast from "../broadcasts/updateSettingsBroadcast";
import {
  FOCUS_MODE_SOUND_ON,
  TIMER_IS_COUNTING_DOWN_ID,
  TIMER_IS_PAUSED_ID,
} from "../focusMode/sharedVariables";
import {
  BACKEND_BROADCAST_VALUES,
  UPDATE_SETTINGS_BROADCAST_VALUES,
  MAIN_CALENDAR_BROADCAST_VALUES,
} from "../lib/broadcastValues";
import {
  getCalendarFromUserCalendarID,
  getCalendarUserEmail,
  getUserCalendarIDFromEmail,
  isCalendarOutlookCalendar,
} from "../lib/calendarFunctions";
import { addDefaultToArray } from "../lib/arrayFunctions";
import { GOOGLE_PLACES_ID } from "../lib/googleFunctions";
import { isMeetWithEvent } from "../lib/meetWithFunctions";
import {
  checkOnlineStatus,
  hasBasicInternetConnection,
  isCloudFlareUp,
} from "../lib/onlineCheckFunctions";
import { isOutlookUser } from "../lib/outlookFunctions";
import { isSameEmail } from "../lib/stringFunctions";
import {
  getUserEmail,
  updateMasterAccountSettings,
  updateUserSettings,
} from "../lib/userFunctions";
import { APP_ELEMENT_ID } from "../lib/vimcalVariables";
import broadcast from "./broadcast";
import { getCalendarIsPrimary } from "./calendarAccessors";
import {
  KEYCODE_COMMAND_FIREFOX,
  KEYCODE_COMMAND_LEFT,
  KEYCODE_COMMAND_RIGHT,
  KEYCODE_CONTROL,
  isMac,
  isElectron,
  handleError,
  getCurrentTimeInCurrentTimeZone,
  isValidTimeZone,
  hasStopEventPropagation,
  hasEventPreventDefault,
  createAbbreviationForTimeZone,
} from "./commonUsefulFunctions";
import {
  AVAILABILITY_PANEL_ID,
  COMBINE_ADJACENT_SLOTS_ID,
  COLOR_SELECTOR_ID,
  EXPANDED_VIEW_CONTAINER_ID,
  HOVER_UPCOMING_EVENT_ID,
  POPUP_EXPANDED_VIEW_ID,
  RSVP_SECTION_ID,
  PERSONAL_LINK_ANIMATION_ID,
  REVERSE_SLOTS_CONTAINER_ID,
  FIND_FREE_TIME_ID,
  PREVIOUSLY_SELECTED_SLOTS_MESSAGE,
  GET_UPCOMING_WEEK_OPENINGS_MESSAGE,
  EVENT_MODAL_ID,
  GROUP_VOTE_DETAIL_CONTAINER,
  FIND_TIMES_MODAL_ID,
  TUTURIAL_WIZARD_MODAL_ID,
  EMAIL_ATTENDEES_MODAL_ID,
  REWIND_2023_MODAL,
  SLOTS_PAGE_ID,
  EVENT_FORM_FIND_TIME_BUTTON_ID,
  CALENDAR_LIST_FIND_TIME_BOTTON_ID,
  AVAILABILITY_PANEL_MODAL_ID,
  SLOTS_ATTENDEE_LOADING_ID,
  GROUP_VOTE_CALENDAR_VIEW_PREVIEW,
  AVAILABILITY_COPY_BUTTON_ID,
  RE_USE_SLOTS_BUTTON_ID,
  EVENT_FORM_ID,
  TEMPLATE_VIEW_ID,
} from "./elementIDVariables";
import {
  getEventUserCalendarID,
  getEventUserEventID,
} from "./eventResourceAccessors";
import {
  AVAILABILITY_PERSONAL_SLOT_CONTAINER_ID,
  BACKEND_MONTH,
  MEETING_DETAIL_ID,
  ROLLING_SEVEN_DAY,
  SET_DISAPPEARING_NOTIFICATION_MESSAGE,
} from "./globalVariables";
import {
  isMaestroUserOnDelegatedAccount,
  isUserDelegatedUser,
} from "./maestroFunctions";
import { SLOTS_SECTIONS } from "./stores/appFunctionality";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey } from "./typeGuards";
import { GUESSED_TIME_ZONE_COPY } from "../lib/copy";

export const BUTTON_TYPE = {
  SQUARE_BUTTON: "MULTI_SELECT",
  RADIO_BUTTON: "RADIO_BUTTON",
};

export function shouldHideRightHandSide({
  hideRightHandSideBar,
  isCreateAvailabilityModeOn,
  isEditModeOn,
  reverseSlotsText,
}) {
  if (isEmptyObjectOrFalsey(hideRightHandSideBar)) {
    return false;
  }

  if (isCreateAvailabilityModeOn || isEditModeOn || reverseSlotsText) {
    return false;
  }

  const { shouldHideRightHandSide } = hideRightHandSideBar;

  return shouldHideRightHandSide;
}

export function isAppInTaskMode({
  isCreateAvailabilityModeOn,
  isEditModeOn,
  reverseSlotsText,
}) {
  return isCreateAvailabilityModeOn || isEditModeOn || !!reverseSlotsText;
}

export function getPopUpEvent({ popupEvent, hoverPopupEvent }) {
  return popupEvent?.event || hoverPopupEvent?.event;
}

export const PREVIEW_EVENT_TYPE = {
  CURRENT_PREVIEWED_EVENT: "CURRENT_PREVIEWED_EVENT",
  CURRENT_HOVER_EVENT: "CURRENT_HOVER_EVENT",
  POPUP_EVENT: "POPUP_EVENT",
};
export function getCurrentPreviewEventType({
  currentPreviewedEvent,
  currentHoverEvent,
}) {
  if (!isEmptyObjectOrFalsey(currentPreviewedEvent)) {
    return PREVIEW_EVENT_TYPE.CURRENT_PREVIEWED_EVENT;
  } else if (!isEmptyObjectOrFalsey(currentHoverEvent)) {
    return PREVIEW_EVENT_TYPE.CURRENT_HOVER_EVENT;
  }

  return PREVIEW_EVENT_TYPE.POPUP_EVENT;
}

export function getPreviewEvent({
  popupEvent,
  hoverPopupEvent,
  currentPreviewedEvent,
  currentHoverEvent,
}) {
  const previewEventType = getCurrentPreviewEventType({
    currentPreviewedEvent,
    currentHoverEvent,
  });

  if (previewEventType === PREVIEW_EVENT_TYPE.CURRENT_PREVIEWED_EVENT) {
    return currentPreviewedEvent;
  } else if (previewEventType === PREVIEW_EVENT_TYPE.CURRENT_HOVER_EVENT) {
    return currentHoverEvent;
  }

  return getPopUpEvent({
    popupEvent,
    hoverPopupEvent,
  });
}

export function getEventClientRect(event, isHover = false) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  let eventDom = document.getElementById(getEventUserEventID(event));
  if (eventDom) {
    let eventCoordinates = eventDom.getBoundingClientRect();
    if (isHover) {
      eventCoordinates.x = eventCoordinates.x - 1;
      eventCoordinates.y = eventCoordinates.y + 1;
    }
    eventDom = null;

    return eventCoordinates;
  }

  eventDom = null;
  return null;
}

export function isModalOpen() {
  return !!document.getElementById(EVENT_MODAL_ID);
}

export function shouldTruncateRightHandPanel(hideRightHandSidebar) {
  return hideRightHandSidebar?.shouldHideRightHandSide;
}

export function isUsePreviouslySelectedSlotsMessageShowing() {
  return !!document.getElementById(PREVIOUSLY_SELECTED_SLOTS_MESSAGE);
}

export function isGetUpcomingWeekAvailabiityMessageShowing() {
  return !!document.getElementById(GET_UPCOMING_WEEK_OPENINGS_MESSAGE);
}

export function isSelectColorPopup(popupEvent) {
  return popupEvent?.isColor;
}

export function isInSearchState() {
  return window.location.href.includes("search");
}

export function isInEditTemplatesState() {
  return window.location.href.includes("edit-templates");
}

export function isInHomeState() {
  return window.location.href.includes("home");
}

export function isInExpandedViewState() {
  return window.location.href.includes("expanded");
}

export function isColorSelectorOpen() {
  return !!document.getElementById(COLOR_SELECTOR_ID);
}

export function isExpandedViewOpen() {
  return !!document.getElementById(EXPANDED_VIEW_CONTAINER_ID);
}

export function isRSVPSectionRendered() {
  return !!document.getElementById(RSVP_SECTION_ID);
}

export function isHoverUpcomingEventShowing() {
  return !!document.getElementById(HOVER_UPCOMING_EVENT_ID);
}

export function isPopUpExpandedViewShowing() {
  return !!document.getElementById(POPUP_EXPANDED_VIEW_ID);
}

export function isFocusModeCountingDown() {
  return !!document.getElementById(TIMER_IS_COUNTING_DOWN_ID);
}

export function isFocusModePaused() {
  return !!document.getElementById(TIMER_IS_PAUSED_ID);
}

export function isFocusModePlayingMusic() {
  return !!document.getElementById(FOCUS_MODE_SOUND_ON);
}

export function isReverseSlotsModalOpen() {
  return !!document.getElementById(REVERSE_SLOTS_CONTAINER_ID);
}

export function isEmailAttendeesModalOpen() {
  return !!document.getElementById(EMAIL_ATTENDEES_MODAL_ID);
}

export function isAISchedulingModalOpen() {
  return !!document.getElementById(FIND_FREE_TIME_ID);
}

export function isAvailabilityPanelShowing() {
  return !!document.getElementById(AVAILABILITY_PANEL_ID);
}

export function isSlotBreakDurationToggleShowing() {
  return !!document.getElementById(COMBINE_ADJACENT_SLOTS_ID);
}

export function shouldDisplayColorPicker(calendar) {
  return !isCalendarOutlookCalendar(calendar);
}

export function isPersonalLinkPanelOpen() {
  return (
    !!document.getElementById(AVAILABILITY_PERSONAL_SLOT_CONTAINER_ID) ||
    !!document.getElementById(PERSONAL_LINK_ANIMATION_ID)
  );
}

export function isGroupVoteDetailPageOpen() {
  return !!document.getElementById(GROUP_VOTE_DETAIL_CONTAINER);
}

export function isGoogleUser(user) {
  return !isOutlookUser(user);
}

export function isSettingsModalShowing() {
  return !!document.getElementById(APP_ELEMENT_ID.SETTINGS_MODAL);
}

export function updateMasterAccountSettingsForFrontendAndBackend({
  masterAccount,
  updatedSettings,
  user,
  isUpdatingExecutiveProfile, // if on delegate user but changing setting for non delegate user
  skipNotification = false,
  shouldRefetchEvents = false,
}) {
  if (
    isMaestroUserOnDelegatedAccount({ masterAccount, user }) &&
    isUpdatingExecutiveProfile
  ) {
    // maestro user and updating for executive
    const updatedUser = updateUserSettings({
      user,
      updatedSettings,
    });

    if (isSettingsModalShowing()) {
      settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
    } else if (skipNotification) {
      // do nothing
    } else {
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Updated settings"
      );
    }
    backendBroadcasts.publish(
      BACKEND_BROADCAST_VALUES.STORE_USER_INFORMATION,
      updatedUser
    );

    // pass in user email and backend should update user properly
    updateSettingsBroadcast.publish(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
      {
        settings: updatedSettings,
        user,
        isUpdatingExecutiveProfile: true,
        shouldRefetchEvents,
      }
    ); // update backend
  } else {
    // normal user
    // most users should come into here
    const updatedMasterAccount = updateMasterAccountSettings({
      masterAccount,
      updatedSettings,
    });

    appBroadcast.publish("UPDATE_MASTER_ACCOUNT", updatedMasterAccount); // update front end - update masteraccount instantly
    if (isSettingsModalShowing()) {
      settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
    } else if (skipNotification) {
      // do nothing
    } else {
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Updated settings"
      );
    }

    updateSettingsBroadcast.publish(
      UPDATE_SETTINGS_BROADCAST_VALUES.UPDATE_SETTINGS_PROPERTY,
      {
        settings: updatedSettings,
        user,
        shouldRefetchEvents,
      }
    ); // update backend
  }
}

export function updateSmartTags({
  smartTags,
  user,
  skipNotification,
  shouldUpdateEventTags = false,
}) {
  if (isSettingsModalShowing()) {
    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
  } else {
    if (!skipNotification) {
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Updated smart tags"
      );
    }
  }

  if (isUserDelegatedUser(user)) {
    backendBroadcasts.publish("UPDATE_USER_SMART_TAGS", {
      user,
      shouldUpdateEventTags,
      smartTags,
    });
    return;
  }
  backendBroadcasts.publish("UPDATE_USER_SMART_TAGS", {
    shouldUpdateEventTags,
    smartTags,
  });
}

export function deleteSmartTag({ userSmartTagId, user, skipNotification }) {
  if (isSettingsModalShowing()) {
    settingsBroadcast.publish("SHOW_SETTINGS_CONFIRMATION");
  } else {
    if (!skipNotification) {
      broadcast.publish(
        SET_DISAPPEARING_NOTIFICATION_MESSAGE,
        "Updated smart tags"
      );
    }
  }
  backendBroadcasts.publish("DELETE_USER_SMART_TAG", { user, userSmartTagId });
}

export function focusOnDefaultModal() {
  const defaultModal = document.getElementById(EVENT_MODAL_ID);
  if (defaultModal?.focus) {
    defaultModal.focus();
  }
}

export function isKeyCodeCommand(keyCode) {
  return (
    isMac() &&
    [
      KEYCODE_COMMAND_LEFT,
      KEYCODE_COMMAND_RIGHT,
      KEYCODE_COMMAND_FIREFOX,
    ].includes(keyCode)
  );
}

export function isKeyCodeControl(keycode) {
  return !isMac() && KEYCODE_CONTROL === keycode;
}

export function isKeyCodeCommandOrControl(keycode) {
  return isKeyCodeCommand(keycode) || isKeyCodeControl(keycode);
}

async function isAppOnlineAndValid() {
  const isOnline = await checkOnlineStatus();
  if (!isOnline) {
    return false;
  }

  const isCloudflareUpAndRunning = await isCloudFlareUp();
  if (!isCloudflareUpAndRunning) {
    return false;
  }

  return true;
}

export async function triggerMenuBarRefreshWithOnlineCheck() {
  const isOnline = await isAppOnlineAndValid();
  if (!isOnline) {
    return;
  }

  triggerRefresh(true);
}

export async function triggerRefreshWithOnlineCheck() {
  // https://www.freecodecamp.org/news/how-to-check-internet-connection-status-with-javascript/
  const isOnline = await isAppOnlineAndValid();
  if (!isOnline) {
    return;
  }

  triggerRefresh();
}

function triggerRefresh(isOnMenuBarRefresh = false) {
  if (!hasBasicInternetConnection()) {
    // last sanity check
    broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      "You are currently offline."
    );
    return;
  }

  if (isElectron() && window?.vimcal) {
    if (isOnMenuBarRefresh) {
      window.vimcal.refreshMenuBar && window.vimcal.refreshMenuBar();
      return;
    }

    window.vimcal.refreshApp && window.vimcal.refreshApp();
    window.vimcal.refreshMenuBar && window.vimcal.refreshMenuBar();
  } else {
    window.location.reload(true);
  }
}

export function getSelectedTextInApp() {
  if (!window?.getSelection) {
    return null;
  }

  if (!window?.getSelection()?.toString) {
    return null;
  }

  return window?.getSelection()?.toString();
}

export function isElementSticky(element) {
  if (!element || !element.getBoundingClientRect || !window?.getComputedStyle) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  const computedStyle = window.getComputedStyle(element);
  return (
    computedStyle?.position === "sticky" && rect?.bottom >= window?.innerHeight
  );
}

export function shouldShowFullLoadingScreenPendingBackendResponse({
  hasReceivedLoginCode,
  isStillPendingInitialCalendarSync,
}) {
  return hasReceivedLoginCode && isStillPendingInitialCalendarSync; // has received code and backend
}

export function isFindTimesModalOpen() {
  return document.getElementById(FIND_TIMES_MODAL_ID);
}

export function isTutorialWizardOpen() {
  return document.getElementById(TUTURIAL_WIZARD_MODAL_ID);
}

export const APP_FROM_WHERE = {
  EVEN_FORM: "EVENT_FORM",
  SLOTS: "SLOTS",
};

export function isRewind2023ModalOpen() {
  return !!document.getElementById(REWIND_2023_MODAL);
}

export function isSlotsDetailSectionOption() {
  return !!document.getElementById(MEETING_DETAIL_ID);
}

export function isSlotsPageOpen() {
  return !!document.getElementById(SLOTS_PAGE_ID);
}

export function isEventFormFindTimeButtonShowing() {
  return !!document.getElementById(EVENT_FORM_FIND_TIME_BUTTON_ID);
}

export function isCalendarListEventFormFindTimeButtonShowing() {
  return !!document.getElementById(CALENDAR_LIST_FIND_TIME_BOTTON_ID);
}

export function isAvailabilityPanelModalOpen() {
  return !!document.getElementById(AVAILABILITY_PANEL_MODAL_ID);
}

export function isSlotsPendingLoadingAttendees() {
  return !!document.getElementById(SLOTS_ATTENDEE_LOADING_ID);
}

export function hasGlobalJoinSettingProperty() {
  return window?.vimcal?.hasToggleForGlobalJoinMeeting;
}

export function hasOpenOnLoginSettingsProperty() {
  return (
    window?.vimcal?.hasToggleForOpenOnLogin &&
    window?.vimcal?.setOpenOnLogin &&
    window?.vimcal?.setDoNotOpenOnLogin
  );
}

export function doesGooglePlacesScriptExist() {
  return !!document.getElementById(GOOGLE_PLACES_ID);
}

export function isGroupVoteCalendarViewPreviewShowing() {
  return !!document.getElementById(GROUP_VOTE_CALENDAR_VIEW_PREVIEW);
}

export function isMonthlyView(selectedCalendarView) {
  return selectedCalendarView === BACKEND_MONTH;
}

export function blurActiveElement() {
  if (document?.activeElement?.blur) {
    document.activeElement.blur();
  }
}

// vh is a string like "90vh"
export function convertVhToPixels(vhString) {
  // Extract the numerical part from the string (removing 'vh')
  const numericValue = parseFloat(vhString);

  // Calculate the pixel value
  return (window.innerHeight * numericValue) / 100;
}

export function isValidCalendarView(calendarView) {
  return [1, 4, 7, ROLLING_SEVEN_DAY, BACKEND_MONTH].includes(calendarView);
}

/**
 * logic -> meet with event > primary calendar current user > primary calendar other user > secondary calendar of current user > secondary calendar
 */
export function getOOOBusyColumnRanking({ event, allCalendars, currentUser }) {
  if (isMeetWithEvent(event)) {
    return 0;
  }
  const currentUserUserCalendarID = getUserCalendarIDFromEmail(
    getUserEmail(currentUser),
    allCalendars
  );
  if (currentUserUserCalendarID === getEventUserCalendarID(event)) {
    return 1;
  }
  const matchingCalendar = getCalendarFromUserCalendarID({
    userCalendarID: getEventUserCalendarID(event),
    allCalendars,
  });
  if (getCalendarIsPrimary(matchingCalendar)) {
    return 2;
  }
  if (
    isSameEmail(
      getUserEmail(currentUser),
      getCalendarUserEmail(matchingCalendar)
    )
  ) {
    // if user email is from current user
    return 3;
  }
  return 4;
}

export function isCopySlotsButtonShowing() {
  return !!document.getElementById(AVAILABILITY_COPY_BUTTON_ID);
}

export function isReUseSlotsButtonShowing() {
  return !!document.getElementById(RE_USE_SLOTS_BUTTON_ID);
}

export function isEventFormShowing() {
  return !!document.getElementById(EVENT_FORM_ID);
}

export function blurCalendar() {
  mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.BLUR_WEEKLY_CALENDAR);
}

export function isTemplateViewShowing() {
  return !!document.getElementById(TEMPLATE_VIEW_ID);
}

export async function copyContent(content) {
  try {
    await navigator.clipboard.writeText(content);
  } catch (e) {
    handleError(e);
  }
}

export function getUserTimeZoneDisplayString({
  format24HourTime,
  userTimeZoneIndex,
  email,
  currentTimeZone,
}) {
  if (!email || !userTimeZoneIndex) {
    return "";
  }
  const timeZone = userTimeZoneIndex[email];
  if (!timeZone) {
    return "";
  }
  const currentTime = getCurrentTimeInCurrentTimeZone(timeZone);
  const currentTimeInCurrentTimeZone = getCurrentTimeInCurrentTimeZone(currentTimeZone);
  const time = format24HourTime
    ? format(currentTime, "HH:mm")
    : format(currentTime, "h:mm a");
  if (!isSameDay(currentTimeInCurrentTimeZone, currentTime)) {
    // need to add +/- 1
    if (isSameDay(addDays(currentTime, 1), currentTimeInCurrentTimeZone)) {
      return `${time} (-1)`;
    }
    if (isSameDay(currentTime, addDays(currentTimeInCurrentTimeZone, 1))) {
      return `${time} (+1)`;
    }
  }
  return time;
}

export function onClickUserLocalTimeZone({timeZone, currentTimeZone}) {
  if (!isValidTimeZone(timeZone)) {
    return;
  }
  if (currentTimeZone === timeZone) {
    broadcast.publish(
      SET_DISAPPEARING_NOTIFICATION_MESSAGE,
      `All events are now in ${createAbbreviationForTimeZone(timeZone)}`,
    );
    return;
  }
  broadcast.publish("SELECT_TIME_ZONE", {
    timeZone,
    customMessage: GUESSED_TIME_ZONE_COPY
  });
}

export function getEventKeyCode(event) {
  return event?.keyCode;
}

export function removeSearchedTimeZone({
  timeZone,
  currentTimeZone,
  temporaryTimeZones,
  event,
  leftHandTimeZone,
}) {
  const isSelectedTimeZone = timeZone === currentTimeZone;
  hasStopEventPropagation(event); // so click doesn't go into select time zone
  hasEventPreventDefault(event);
  if (isEmptyArrayOrFalsey(temporaryTimeZones)) {
    return;
  }
  let updatedFilteredTemporaryTimeZone = addDefaultToArray(temporaryTimeZones?.filter(
    (tz) => tz !== timeZone
  ));
  if (updatedFilteredTemporaryTimeZone.length === 0) {
    // remove all time zones
    broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
  }
  if (updatedFilteredTemporaryTimeZone.length === 1 && updatedFilteredTemporaryTimeZone[0] === leftHandTimeZone) {
    // remove all time zones
    // if all that is left is the permanent time zone
    broadcast.publish("RETURN_TO_DEFAULT_TIME_ZONE");
    updatedFilteredTemporaryTimeZone = []; // reset to empty array
  }
  // remove timezone and select last filtered time zone
  if (isSelectedTimeZone) {
    const lastFilteredTimeZone =
      updatedFilteredTemporaryTimeZone[
        updatedFilteredTemporaryTimeZone.length - 1
      ];
    broadcast.publish("SELECT_TIME_ZONE", {
      timeZone: lastFilteredTimeZone,
      isToggleTimeZone: true,
      updatedFilteredTemporaryTimeZone,
    });
  } else {
    mainCalendarBroadcast.publish(MAIN_CALENDAR_BROADCAST_VALUES.REMOVE_UNSELECTED_TIME_ZONE, updatedFilteredTemporaryTimeZone);
  }
}

export function getSlotsDefaultOpenSections(slotsDefaultOpenSections) {
  const isStylingSectionExpanded = slotsDefaultOpenSections?.includes(SLOTS_SECTIONS.STYLING) || false;
  const isHoldsSectionExpanded = slotsDefaultOpenSections?.includes(SLOTS_SECTIONS.HOLDS) || false;
  const isPersonalizeSectionExpanded = slotsDefaultOpenSections?.includes(SLOTS_SECTIONS.PERSONALIZE_INVITE) || false;
  const isMeetingDetailsExpanded = slotsDefaultOpenSections?.includes(SLOTS_SECTIONS.MEETING_DETAILS) || false;
  return {
    isStylingSectionExpanded,
    isHoldsSectionExpanded,
    isPersonalizeSectionExpanded,
    isMeetingDetailsExpanded,
  };
}
